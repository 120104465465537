import { Box, Button, CircularProgress, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { LinearProgress } from "@material-ui/core";
import { AdjustmentsAddCovSrcApi, AdjustmentsAddDatasegApi, AdjustmentsAddOpenApi, AdjustmentsSaveApi, AdjustmentsNetworkIdApi, AdjustmentsUpdateApi, AdjustmentsAddContrArrApi, AdjustmentsSearchDeleteApi, AdjustmentsAddPolIdApi } from "../AdjustmentsAPI";

export const SearchUpdateAdd = ({
    inputDataSegments,
    inputData,
    addOrUpdate,
    switchTab
}) => {
    const [addOpenApiLoading, setAddOpenApiLoading] = useState(true);
    const [loading, setLoading] = useState(true);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [preloadData, setPreloadData] = useState({});
    const [useOr1, setUseOr1] = useState(true);
    const [feedback, setFeedback] = useState([]);
    const [invoiceNumber, setInvoiceNumber] = useState(preloadData['INVOICE_NUMBER']);
    const [status, setStatus] = useState('');


    // for disabling/enabling components
    const [disabledComponents, setDisabledComponents] = useState({
        'providerMemberSelect': false,
        'capPeriod': false,
        'currentRetroSelect': false,
        'memberId': false,
        'vendorNumber': false,
        'taxId': false,
        'addressSequenceNumber': false,
        'payNowSelect': false,
        'reverseSelect': false
    });

    // selected
    const [selectedDataSegment, setSelectedDataSegment] = useState('');
    const [procYrMo, setProcYrMo] = useState('');
    const [capPeriod, setCapPeriod] = useState('');
    const [selectedCovSrcSys, setSelectedCovSrcSys] = useState('');
    const [selectedEmployerDivGroup, setSelectedEmployerDivGroup] = useState('');
    const [selectedProductCode, setSelectedProductCode] = useState('');
    const [selectedFundingArrangement, setSelectedFundingArrangement] = useState('');
    const [selectedPlanStateOfIssue, setSelectedPlanStateOfIssue] = useState('');
    const [selectedSpecialtyCode, setSelectedSpecialtyCode] = useState('');
    const [selectedAdjustmentCategoryCode, setSelectedAdjustmentCategoryCode] = useState('');

    // options
    const [typeOptions, setTypeOptions] = useState({
        '': '',
        'CAP ADJ': 'CAPADJ',
        'PAYMENT ADJ': 'PAYADJ'
    });
    const [providerMemberOptions, setProviderMemberOptions] = useState([
        '',
        'MEMBER',
        'PROVIDER'
    ]);
    const [dataSegments, setDataSegments] = useState({});
    const [providerArrangementOptions, setProviderArrangementOptions] = useState({});
    const [contractArrangmentOptions, setContractArrangmentOptions] = useState({});
    const [currentRetroOptions, setCurrentRetroOptions] = useState({});
    const [coverageSourceSystemOptions, setCoverageSourceSystemOptions] = useState({});
    const [employerDivGroupOptions, setEmployerDivGroupOptions] = useState([]);
    const [hmoAdcOptions, setHmoAdcOptions] = useState({});
    const [temporaryProductCodeOption, setTemporaryProductCodeOption] = useState('');
    const [productCodeOptions, setProductCodeOptions] = useState({});
    const [productClassIdOptions, setProductClassIdOptions] = useState({});
    const [financialMarketSegmentCodeOptions, setFinancialMarketSegmentCodeOptions] = useState({});
    const [fundingArrangementOptions, setFundingArrangementOptions] = useState({});
    const [planStateOfIssueOptions, setPlanStateOfIssueOptions] = useState({});
    const [providerNetworkOptions, setProviderNetworkOptions] = useState({});
    const [specialtyCodeOptions, setSpecialtyCodeOptions] = useState({});
    const [adjustmentCategoryCodeOptions, setAdjustmentCategoryCodeOptions] = useState({});
    const yesNoOptions = {
        'NO': 'N',
        'YES': 'Y'
    };
    const [extensionOptions, setExtensionOptions] = useState([
        'DOC',
        'PDF',
        'RTF',
        'TXT',
        'XLS',
        'ZIP'
    ]);

    useEffect(() => {
        if (inputDataSegments) {
            setDataSegments(inputDataSegments);
        }
    }, [inputDataSegments]);

    /**
     * Functions to update selected options (autofill preload data once select options are retrieved)
     * Needed because options are received after preload data
     */
    function updateProviderArrangement(data) {
        if (Object.keys(data).includes('PA')) {
            var temp = Object.keys(providerArrangementOptions);
            for (let i = 0; i < temp.length; i++) {
                if (providerArrangementOptions[temp[i]] == data['PA']) {
                    document.getElementById('providerArrangementSelect').value = temp[i];
                    return;
                }
            }
        }
    }
    useEffect(() => {
        updateProviderArrangement(preloadData);
    }, [providerArrangementOptions]);


    function updateContractArrangement(data) {
        if (data && Object.keys(data).includes('CONTRARRCD')) {
            var temp = Object.keys(contractArrangmentOptions);
            for (let i = 0; i < temp.length; i++) {
                if (contractArrangmentOptions[temp[i]] == data['CONTRARRCD']) {
                    document.getElementById('contractArrangmentSelect').value = temp[i];
                    return;
                }
            }
        }
    }
    useEffect(() => {
        updateContractArrangement(preloadData);
    }, [contractArrangmentOptions]);


    function updateCurrentRetro(data) {
        if (Object.keys(data).includes('ACURRETCD')) {
            var temp = Object.keys(currentRetroOptions);
            for (let i = 0; i < temp.length; i++) {
                if (currentRetroOptions[temp[i]] == data['ACURRETCD']) {
                    document.getElementById('currentRetroSelect').value = currentRetroOptions[temp[i]];
                    return;
                }
            }
        }
    }
    useEffect(() => {
        updateCurrentRetro(preloadData);
    }, [currentRetroOptions]);

    function updateProductCode(data) {
        if (Object.keys(data).includes('PRDCTCD')) {
            var temp = Object.keys(productCodeOptions);
            for (let i = 0; i < temp.length; i++) {
                if (productCodeOptions[temp[i]] == data['PRDCTCD']) {
                    setSelectedProductCode(temp[i]);
                    return;
                }
            }
        }
    }
    useEffect(() => {
        updateProductCode(preloadData);
    }, [productCodeOptions]);

    function updateCoverageSourceSystem(data) {
        if (Object.keys(data).includes('COVSRCSYSCD')) {
            var temp = Object.keys(coverageSourceSystemOptions);
            for (let i = 0; i < temp.length; i++) {
                if (coverageSourceSystemOptions[temp[i]] == data['COVSRCSYSCD']) {
                    setSelectedCovSrcSys(temp[i]);
                    return;
                }
            }
        }
    }
    useEffect(() => {
        updateCoverageSourceSystem(preloadData);
    }, [coverageSourceSystemOptions]);

    function updateEmployerDivGroup(data) {
        if (Object.keys(data).includes('POLID')) {
            for (let i = 0; i < employerDivGroupOptions.length; i++) {
                if (employerDivGroupOptions.includes(data['POLID'])) {
                    setSelectedEmployerDivGroup(data['POLID']);
                    return;
                }
            }
        }
    }
    useEffect(() => {
        updateEmployerDivGroup(preloadData);
    }, [employerDivGroupOptions]);

    function updateHmoAdc(data) {
        if (Object.keys(data).includes('HMOACCTDIVCD') && data['HMOACCTDIVCD']) {
            document.getElementById('hmoAdcSelect').value = data['HMOACCTDIVCD'];
        }
    }
    useEffect(() => {
        updateHmoAdc(preloadData);
    }, [hmoAdcOptions]);

    function updateFinancialMarketSegmentCode(data) {
        if (Object.keys(data).includes('FINCMKTSEGCD')) {
            var temp = Object.keys(financialMarketSegmentCodeOptions);
            for (let i = 0; i < temp.length; i++) {
                if (financialMarketSegmentCodeOptions[temp[i]] == data['FINCMKTSEGCD']) {
                    document.getElementById('financialMarketSegmentCodeSelect').value = temp[i];
                    return;
                }
            }
        }
    }
    useEffect(() => {
        updateFinancialMarketSegmentCode(preloadData);
    }, [financialMarketSegmentCodeOptions]);

    function updateFundingArrangement(data) {
        if (Object.keys(data).includes('FUNDARNGCD')) {
            var temp = Object.keys(fundingArrangementOptions);
            for (let i = 0; i < temp.length; i++) {
                if (fundingArrangementOptions[temp[i]] == data['FUNDARNGCD']) {
                    setSelectedFundingArrangement(temp[i]);
                    return;
                }
            }
        }
    }
    useEffect(() => {
        updateFundingArrangement(preloadData);
    }, [fundingArrangementOptions]);

    function updatePlanStateOfIssue(data) {
        if (Object.keys(data).includes('POLISSSTCD')) {
            var temp = Object.keys(planStateOfIssueOptions);
            for (let i = 0; i < temp.length; i++) {
                if (planStateOfIssueOptions[temp[i]] == data['POLISSSTCD']) {
                    setSelectedPlanStateOfIssue(temp[i]);
                    return;
                }
            }
        }
    }
    useEffect(() => {
        updatePlanStateOfIssue(preloadData);
    }, [planStateOfIssueOptions]);

    function updateProviderNetwork(data) {
        if (Object.keys(data).includes('PCPNTWKID')) {
            var temp = Object.keys(providerNetworkOptions);
            for (let i = 0; i < temp.length; i++) {
                if (providerNetworkOptions[temp[i]] == data['PCPNTWKID']) {
                    document.getElementById('providerNetworkSelect').value = temp[i];
                    return;
                }
            }
        }
    }
    useEffect(() => {
        updateProviderNetwork(preloadData);
    }, [providerNetworkOptions]);

    function updateSpecialtyCode(data) {
        if (Object.keys(data).includes('SPCLCD')) {
            var temp = Object.keys(specialtyCodeOptions);
            for (let i = 0; i < temp.length; i++) {
                if (specialtyCodeOptions[temp[i]] == data['SPCLCD']) {
                    setSelectedSpecialtyCode(temp[i]);
                    return;
                }
            }
        }
    }
    useEffect(() => {
        updateSpecialtyCode(preloadData);
    }, [specialtyCodeOptions]);


    function updateAdjustmentCategoryCode(data) {
        if (Object.keys(data).includes('AINVADJCTGYCD')) {
            var temp = Object.keys(adjustmentCategoryCodeOptions);
            for (let i = 0; i < temp.length; i++) {
                if (adjustmentCategoryCodeOptions[temp[i]] == data['AINVADJCTGYCD']) {
                    setSelectedAdjustmentCategoryCode(temp[i]);
                    return;
                }
            }
        }
    }
    useEffect(() => {
        updateAdjustmentCategoryCode(preloadData);
    }, [adjustmentCategoryCodeOptions]);

    // set component values based on data from search selection
    useEffect(() => {
        var temp = inputData;
        if (Object.keys(inputData).includes('TYPE')) {
            let tempVar = inputData['TYPE'];
            if (tempVar === 'PAYADJ') {
                document.getElementById('typeSelect').value = 'PAYMENT ADJ';
                document.getElementById('providerMemberSelect').value = 'PROVIDER';
                typeChange('PAYMENT ADJ');
                providerMemberChange('PROVIDER');
            } else if (tempVar === 'CAPADJ') {
                document.getElementById('typeSelect').value = 'CAP ADJ';
                typeChange('CAP ADJ');
            }
        }
        if (Object.keys(inputData).includes('DATA_SEGMENT')) {
            setSelectedDataSegment(inputData['DATA_SEGMENT']);
        }
        if (Object.keys(inputData).includes('PROC_YR_MONTH')) {
            setProcYrMo(inputData['PROC_YR_MONTH']);
        }
        if (Object.keys(inputData).includes('AVENDNBR')) {
            document.getElementById('vendorNumber').value = inputData['AVENDNBR'] === 'None' ? '' : inputData['AVENDNBR'];
        }
        if (Object.keys(inputData).includes('PROADRSEQNUM')) {
            document.getElementById('addressSequenceNumber').value = inputData['PROADRSEQNUM'];
        }
        if (Object.keys(inputData).includes('PAYNOW/REVERSE')) {
            let tempList = inputData['PAYNOW/REVERSE'].split('/');
            let tempKeys = Object.keys(yesNoOptions);
            for (let i = 0; i < tempKeys.length; i++) {
                if (tempKeys[i] == tempList[0].toUpperCase()) {
                    document.getElementById('payNowSelect').value = tempKeys[i];
                }
                if (tempKeys[i] == tempList[1].toUpperCase()) {
                    document.getElementById('reverseSelect').value = tempKeys[i];
                }
            }
        }
        if (Object.keys(inputData).includes('ABCKFLEXT')) {
            document.getElementById('extensionSelect').value = inputData['ABCKFLEXT'];
        }
        setPreloadData(temp);
    }, [inputData]);


    function AdjustmentsAddContrArrOptions(data_segment) {
        var temp = {
            'GLOBAL': 'GL',
            'PARTIAL RISK W INCENTIVE': 'P1',
            'SUBCAP': 'SC',
            'PRIMARY RISK - MSO': 'PM',
            'THIRD PARTY': 'T1',
            'HOSPITAL': 'H0',
            'PRIMARY RISK': 'PR',
            'THIRD PARTY': 'TP',
            'PRIMARY CARE - MSO PCP': 'PS',
            'PARTIAL': 'PA',
            'PARTIAL RISK WITH CMF': 'P2',
        }
        var options_dict = {}
        // don't make api call if no data segment is selected
        if (!data_segment) {
            return;
        }
        const requestBody = {
            "ecap_typ_cd": `CONTARRG${data_segment}`
        };

        AdjustmentsAddContrArrApi(requestBody).then(response => {
            for (let i = 0; i < response.data.result.length; i++) {
                options_dict[response.data.result[i]['SHRT_DESC']] = temp[response.data.result[i]['SHRT_DESC']];
            }
            setContractArrangmentOptions(options_dict);
        }).catch(error => {
            console.log('AdjustmentsAddContrArrApi:', error);
            setContractArrangmentOptions({ "No Contract Arrangements for this Data Segment": 'NA' });
            return;
        });
    }


    /**
     * addOpenApi fills in default values given no data segment is selected
     */
    function addOpenApi() {
        AdjustmentsAddOpenApi({}).then(response => {
            try {
                setProviderArrangementOptions(response.data[0]['PROVARRGUI']);
                setCurrentRetroOptions(response.data[1]['CAPTYPE']);
                setAdjustmentCategoryCodeOptions(response.data[2]['ADJCATCD']);
                setSpecialtyCodeOptions(response.data[3]['SPECIALTYCD']);
                setProductCodeOptions(response.data[4]['INVCADJPRDCD']);
                setFinancialMarketSegmentCodeOptions(response.data[5]['FINMKTSEGCD']);
                setFundingArrangementOptions(response.data[6]['FUNDARNG']);
                setProductClassIdOptions(response.data[7]['PRDCLSSID']);
                setProviderNetworkOptions(response.data[8]['PROVNTWKCS']);
                AdjustmentsAddContrArrOptions(inputData['DATA_SEGMENT']);
            } catch {
                console.log('error using data from adjustments add/open');
            }
            setLoading(false);
            setAddOpenApiLoading(false);
        }).catch(error => {
            console.log('AdjustmentsAddOpenApi:', error);
            setLoading(false);
            setAddOpenApiLoading(false);
        })
    }

    // add/open api
    useEffect(() => {
        addOpenApi();
    }, []);

    useEffect(() => {
        if (!addOpenApiLoading && selectedDataSegment) {
            dataSegmentChange(selectedDataSegment);
        }
    }, [addOpenApiLoading])

    // add/dataseg api
    async function dataSegmentChange(value) {
        setTemporaryProductCodeOption('');
        setSelectedDataSegment(value);
        if (!addOpenApiLoading && value && value !== '---Select---') {
            setLoading(true);
            AdjustmentsAddDatasegApi({ 'data_segment': value }).then(async response => {
                try {
                    setHmoAdcOptions(response.data[0]['HMOADC']);
                    setPlanStateOfIssueOptions(response.data[1]['PLCYSTISS']);
                    setProductClassIdOptions(response.data[2]['PRDCLSSID']);
                    setProductCodeOptions(response.data[3]['INVCADJPRDCD']);
                    setFinancialMarketSegmentCodeOptions(response.data[4]['FINMKTSEGCD']);
                    setCoverageSourceSystemOptions(response.data[5]['COVSRCSYS']);
                    AdjustmentsAddContrArrOptions(value);
                } catch {
                    console.log('error using data from adjustments add/dataseg');
                }
                setLoading(false);
            }).catch(error => {
                console.log('AdjustmentsAddDatasegApi:', error);
                setLoading(false);
            });
            // waiting on logic for this
            setProviderNetworkOptions({});
        }
    }

    // add/covsrcsys api
    useEffect(() => {
        if (
            procYrMo &&
            procYrMo > 99999 &&
            parseInt(procYrMo.slice(4)) !== 0 &&
            parseInt(procYrMo.slice(4)) < 13 &&
            selectedCovSrcSys &&
            selectedCovSrcSys !== '--Select--'
        ) {
            let requestBody = {
                "coverage_code": selectedCovSrcSys
            }
            let year = parseInt(procYrMo.substring(0, 4), 10);
            let month = parseInt(procYrMo.substring(4, 6), 10);
            let firstDay = new Date(year, month - 1, 1);
            // let lastDay = new Date(year, month, 0);
            requestBody['start_date'] = firstDay.toISOString().split('T')[0];
            // requestBody['end_date'] = lastDay.toISOString().split('T')[0];
            requestBody['end_date'] = requestBody['start_date'];
            setLoading(true);
            AdjustmentsAddCovSrcApi(requestBody).then(response => {
                setEmployerDivGroupOptions(response.data['policies']);
                setLoading(false);
            }).catch(error => {
                console.log('AdjustmentsAddCovSrcApi:', error);
                setLoading(false);
            });
        }
    }, [procYrMo, selectedCovSrcSys]);

    const typographyStyle = {
        width: 215,
        fontSize: '0.8rem',
        whiteSpace: 'nowrap'
    };

    const selectStyle = {
        fontSize: '0.8rem',
        padding: '2px',
        margin: 0,
        boxSizing: 'border-box'
    };

    const inputStyle = {
        fontSize: '0.8rem',
        padding: '0px',
        margin: 0,
        boxSizing: 'border-box'
    };

    /** FUNCTIONS TO DETERMINE FUNCTIONALITY ON CHANGE */
    function typeChange(value) {
        if (value === 'CAP ADJ') {
            setDisabledComponents({
                'providerMemberSelect': false,
                'capPeriod': false,
                'currentRetroSelect': true,
                'memberId': document.getElementById('providerMemberSelect').value === 'PROVIDER' ? true : false,
                'vendorNumber': true,
                'taxId': true,
                'addressSequenceNumber': true,
                'payNowSelect': true,
                'reverseSelect': true
            });
            document.getElementById('currentRetroSelect').value = '';
            document.getElementById('vendorNumber').value = '';
            document.getElementById('taxId').value = '';
            document.getElementById('addressSequenceNumber').value = '';
            setUseOr1(true);
        } else if (value === 'PAYMENT ADJ') {
            setDisabledComponents({
                'providerMemberSelect': true,
                'capPeriod': true,
                'currentRetroSelect': false,
                'memberId': true,
                'vendorNumber': false,
                'taxId': false,
                'addressSequenceNumber': false,
                'payNowSelect': false,
                'reverseSelect': false
            });
            document.getElementById('providerMemberSelect').value = 'PROVIDER';
        } else {
            setDisabledComponents({
                'providerMemberSelect': false,
                'capPeriod': false,
                'currentRetroSelect': false,
                'memberId': false,
                'vendorNumber': false,
                'taxId': false,
                'addressSequenceNumber': false,
                'payNowSelect': false,
                'reverseSelect': false
            });
        }
    }

    function providerMemberChange(value) {
        if (value === 'PROVIDER') {
            setDisabledComponents(prevState => ({
                ...prevState,
                memberId: true
            }))
        } else {
            setDisabledComponents(prevState => ({
                ...prevState,
                memberId: false
            }))
        }
    }

    function pol_id_change(value) {
        setSelectedEmployerDivGroup(value);
        if (value) {
            var requestBody = {
                "COV_SRC_SYS_CD": selectedCovSrcSys,
                "POL_ID": value,
            }
            let year = parseInt(procYrMo.substring(0, 4), 10);
            let month = parseInt(procYrMo.substring(4, 6), 10);
            let firstDay = new Date(year, month - 1, 1);
            // let lastDay = new Date(year, month, 0);
            requestBody['COV_EFF_DT'] = firstDay.toISOString().split('T')[0];
            // requestBody['end_date'] = lastDay.toISOString().split('T')[0];
            requestBody['COV_END_DT'] = requestBody['COV_EFF_DT'];
            AdjustmentsAddPolIdApi(requestBody).then(response => {
                if (response.data.length > 0) {
                    var temp = response.data[0];
                    document.getElementById('financialMarketSegmentCodeSelect').value = temp['Fi_Mkt_Seg_Cd'];
                    setSelectedFundingArrangement(temp['Funding_Arrg']);
                    setSelectedPlanStateOfIssue(temp['Plan_State_Issue']);
                    if (Object.keys(productCodeOptions).includes(temp['Product_Code'] + ' ' + temp['Product_Code_desc'])) {
                        setSelectedProductCode(temp['Product_Code'] + temp['Product_Code_desc']);
                    } else {
                        setSelectedProductCode('');
                        setTemporaryProductCodeOption(temp['Product_Code'] + (temp['Product_Code_desc'] === undefined ? 'UNKNOWN' : temp['Product_Code_desc']));
                    }
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    useEffect(() => {
        if (capPeriod) {
            var tempYearMo = capPeriod;
        } else {
            var tempYearMo = procYrMo;
        }
        if (tempYearMo && parseInt(tempYearMo) > 99999 && parseInt(tempYearMo.substring(4, 6)) > 0 && parseInt(tempYearMo.substring(4, 6)) < 13 && selectedEmployerDivGroup) {
            // parse the date first into sql date format
            var year = tempYearMo.substring(0, 4);
            var month = tempYearMo.substring(4, 6);
            const date = new Date(`${year}-${month}-01`);
            date.setDate(date.getDate() + 15);
            year = date.getFullYear();
            month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const sql_date = `${year}-${month}-${day}`
            var requestBody = {
                "emp_div": selectedEmployerDivGroup,
                "cap_prc_month": sql_date
            }
            setLoading(true);
            AdjustmentsNetworkIdApi(requestBody).then(response => {
                console.log(response);
                if (response.data.length > 0) {
                    var temp = {}
                    for (let i = 0; i < response.data.length; i++) {
                        temp[response.data[i]] = response.data[i];
                    }
                    setProviderNetworkOptions(temp);
                }
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                console.log(error);
            })
        }
    }, [procYrMo, selectedEmployerDivGroup, capPeriod])

    function resetAll() {
        setFeedback([]);
        document.getElementById('typeSelect').value = ''
        document.getElementById('providerMemberSelect').value = ''
        setSelectedDataSegment('');
        setProcYrMo('');
        document.getElementById('providerArrangementSelect').value = '';
        document.getElementById('contractArrangmentSelect').value = '';
        document.getElementById('capPeriod').value = '';
        document.getElementById('currentRetroSelect').value = '';
        document.getElementById('amount').value = '';
        document.getElementById('description').value = '';
        document.getElementById('memberId1').value = '';
        document.getElementById('memberId2').value = '';
        document.getElementById('memberId3').value = '';
        setSelectedCovSrcSys('');
        setSelectedEmployerDivGroup('');
        document.getElementById('hmoAdcSelect').value = '--Select--';
        setSelectedProductCode('');
        setTemporaryProductCodeOption('');
        document.getElementById('productClassIdSelect').value = '';
        document.getElementById('financialMarketSegmentCodeSelect').value = '';
        setSelectedFundingArrangement('');
        setSelectedPlanStateOfIssue('');
        document.getElementById('providerNetworkSelect').value = '';
        document.getElementById('providerContractNumber').value = '';
        document.getElementById('providerContractLineNumber').value = '';
        document.getElementById('vendorNumber').value = '';
        document.getElementById('taxId').value = '';
        document.getElementById('addressSequenceNumber').value = '';
        document.getElementById('contractOwnerMpin').value = '';
        document.getElementById('assocGroup').value = '';
        setSelectedSpecialtyCode('');
        setSelectedAdjustmentCategoryCode('');
        document.getElementById('adjustmentCode').value = '';
        document.getElementById('payNowSelect').value = 'NO';
        document.getElementById('reverseSelect').value = 'NO';
        document.getElementById('extensionSelect').value = 'DOC';
        addOpenApi();
        setLoading(true);
    }

    function getBodyFromUI() {
        let requestBody = {
            "MTCN": preloadData['AMTCN'],  // AMTCN
            "AUDIT_GEN_IND": preloadData['AUDITGENIND'],  // AUDITGENIND
            "INVC_ADJ_TYP_CD": typeOptions[document.getElementById('typeSelect').value],  // AINVADJTYPCD
            "DATA_SEG_ID": Object.keys(dataSegments).find(key => dataSegments[key] === selectedDataSegment) === undefined ? '' : Object.keys(dataSegments).find(key => dataSegments[key] === selectedDataSegment),  // ADATASEGID
            "INVC_REF_ID": preloadData['INVOICE_NUMBER'],  // AINVREFID
            "INVC_ID": preloadData['AINV_ID'],  // AINV_ID
            "INVC_ADJ_CATGY_CD": adjustmentCategoryCodeOptions[selectedAdjustmentCategoryCode],  // AINVADJCTGYCD
            "INVC_LN_NBR": preloadData['AINVLNNBR'],  // AINVLNNBR
            "ADJ_CATGY_CD": "",  // AADJCTGYCD unsure, same as AINVADJCTGYCD?
            "INVC_ADJ_STS_CD": preloadData['STATUS'],  // AINVADJSTSCD
            "INVC_ADJ_RSN_CD": document.getElementById('adjustmentCode').value,  // AINVADJRSCD
            "PROC_YR_MO": procYrMo,  // APROCYRMO
            "CAP_PRD_YR_MO": procYrMo,  // ACAPPRDYRMO unsure, same as APROCYRMO?
            "INVC_ADJ_AMT": document.getElementById('amount').value,  // AINVADJAMT
            "PAY_TO_PROV_TAX_ID": preloadData['APAY2PRVTXID'],  // APAY2PRVTXID
            "VEND_NBR": document.getElementById('vendorNumber').value,  // AVENDNBR
            "PAY_TO_PROV_ID": preloadData['APAY2PRVID'],  // APAY2PRVID
            "OWNR_PROV_ID": document.getElementById('contractOwnerMpin').value,  // AONRPRVID
            "CONTR_ID": document.getElementById('providerContractNumber').value,  // ACNTID
            "CONTR_LN_ID": document.getElementById('providerContractLineNumber').value,  // ACNTLNID
            "PROV_ARNG_CD": providerArrangementOptions[document.getElementById('providerArrangementSelect').value],  // APRVARGCD
            "MBR_SRC_SYS_CD": preloadData['AMBRSRSYCD'],  // AMBRSRSYCD
            "CUST_ID": document.getElementById('memberId1').value,  // ACUSTID
            "MBR_ID": document.getElementById('memberId2').value,  // AMBRID
            "MBR_ID_SUFX": document.getElementById('memberId3').value,  // AMBRIDSUX
            "CURR_RETRO_CD": document.getElementById('currentRetroSelect').value,  // ACURRETCD
            "PAY_NOW_IND": yesNoOptions[document.getElementById('payNowSelect').value],  // APAYNIND
            "AUTO_RVRS_IND": yesNoOptions[document.getElementById('reverseSelect').value],  // AAURVIND
            "INVC_DESC": document.getElementById('description').value,  // AINVDESC
            "BCKUP_FL_PTH": preloadData['ABCKFLPTH'],  // ABCKFLPTH
            "BCKUP_FL_EXT": document.getElementById('extensionSelect').value,  // ABCKFLEXT
            "UPDT_USER_ID": localStorage.getItem('sub'),  // AUPDTUID
            "UPDT_DTTM": "CURRENT_TIMESTAMP",  // AUPDTDTTM
            "ASSOC_PROV_ID": document.getElementById('assocGroup').value,  // AASSOCPID
            "PROV_ADR_SEQ_NBR": document.getElementById('addressSequenceNumber').value,  // PROADRSEQNUM
            "HMO_ACCT_DIV_CD": document.getElementById('hmoAdcSelect').value === '--Select--' ? '' : hmoAdcOptions[document.getElementById('hmoAdcSelect').value],  // HMOACCTDIVCD
            "PRDCT_CD": productCodeOptions[selectedProductCode],  // PRDCTCD
            "FINC_MKT_SEG_CD": document.getElementById('financialMarketSegmentCodeSelect').value === '--Select--' ? '' : financialMarketSegmentCodeOptions[document.getElementById('financialMarketSegmentCodeSelect').value],  // FINCMKTSEGCD
            "FUND_ARNG_CD": fundingArrangementOptions[selectedFundingArrangement],  // FUNDARNGCD
            "SPCL_CD": specialtyCodeOptions[selectedSpecialtyCode],  // SPCLCD
            "PRDCT_CLSS_ID": productClassIdOptions[document.getElementById('productClassIdSelect').value],  // PRDCTCLSSID
            "GLB_CPTN_IND": preloadData['GLBCPTNIND'],  // GLBCPTNIND
            "PAY_TO_IND": preloadData['PAYTOIND'],  // PAYTOIND
            "CONTR_TYP_CD": preloadData['CONTRTYPCD'],  // CONTRTYPCD
            "POL_ISS_ST_CD": planStateOfIssueOptions[selectedPlanStateOfIssue],  // POLISSSTCD
            "CONTR_ARNG_CD": contractArrangmentOptions[document.getElementById('contractArrangmentSelect').value],  // CONTRARRCD
            "TOPS_PRDCT_CD": preloadData['TOPSPRDCTCD'],  // TOPSPRDCTCD
            "COV_SRC_SYS_CD": selectedCovSrcSys,  // COVSRCSYSCD
            "POL_ID": selectedEmployerDivGroup,  // POLID
            "PCP_NTWK_ID": providerNetworkOptions[document.getElementById('providerNetworkSelect').value],  // PCPNTWKID
            "CMS_CONTR_ID": preloadData['CMSCONTRID'],  // CMSCONTRID
            "PLN_BEN_PCK_ID": preloadData['PLNBENPCKID'],  // PLNBENPCKID
            "INVC_ADJ_ID": preloadData['AINVADJID'],  // unique id AINVADJID
        }
        let keys = Object.keys(requestBody);
        for (let i = 0; i < keys.length; i++) {
            if (!requestBody[keys[i]]) {
                requestBody[keys[i]] = '';
            }
        }
        return requestBody;
    }

    function update() {

        setFeedback([]);
        var validUpdate = true;
        /** frontend validations first */
        if (!validUpdate) {
            return
        }
        setUpdateLoading(true);
        var requestBody = getBodyFromUI();
        console.log('Update request:', requestBody);

        AdjustmentsUpdateApi(requestBody).then(response => {
            // console.log('Update response:', response.data);
            setUpdateLoading(false);
            if (response.data.includes('Success')) {
                // console.log('Update successful');
                setFeedback([['success', 'Update Successful']]);
            } else {
                setFeedback([['fail', 'Update Failed']]);
            }
        }).catch(error => {
            // console.log('Update error:', error);
            setUpdateLoading(false);
            setFeedback([['fail', 'Update Failed']]);
        });
    }


    function handleDelete() {
        var requestBody = getBodyFromUI();
        setFeedback([]);
        setDeleteLoading(true);
        requestBody['PROV_MEM'] = document.getElementById('providerMemberSelect').value;
        requestBody['SRC_FL_NM'] = preloadData['INVOICE_NUMBER'];
        requestBody['DOC_TYP_CD'] = 'BKP';
        // // test data
        // requestBody['PROV_MEM'] = 'MEMBER';
        // requestBody['INVC_ADJ_ID'] = 1;
        // requestBody['SRC_FL_NM'] = 'test_RPT_201303_009_009_001_EC7030_A01_000000468';

        AdjustmentsSearchDeleteApi(requestBody).then(response => {
            console.log('Delete response:', response);
            setDeleteLoading(false);
            if (response.status === 200) {
                setFeedback([['success', 'Delete Successful']]);
                setTimeout(() => {
                    switchTab(0);
                }, 2500);
            } else {
                setFeedback([['fail', 'Delete Failed']]);
            }
        }).catch(error => {
            setDeleteLoading(false);
            setFeedback([['fail', 'Delete Failed']]);
        });
    }


    function save() {
        setFeedback([]);
        var validSave = true;
        /** frontend validations first */
        if (!validSave) {
            return
        }

        const procYrMoElement = document.getElementById('procYrMo');
        const fundingArrangementElement = document.getElementById('fundingArrangement');
        const providerArrangementElement = document.getElementById('providerArrangementSelect');
        const contractArrangementElement = document.getElementById('contractArrangmentSelect');
        const amountElement = document.getElementById('amount');
        const descriptionElement = document.getElementById('description');
        const coverageSourceSystemElement = document.getElementById('coverageSourceSystem');
        const employerDivGroupElement = document.getElementById('employerDivGroup');
        const hmoAdcElement = document.getElementById('hmoAdcSelect');
        const productCodeElement = document.getElementById('productCode');
        const productClassIdElement = document.getElementById('productClassIdSelect');
        const financialMarketSegmentCodeElement = document.getElementById('financialMarketSegmentCodeSelect');
        const providerNetworkElement = document.getElementById('providerNetworkSelect');
        const providerContractNumberElement = document.getElementById('providerContractNumber');
        const providerContractLineNumberElement = document.getElementById('providerContractLineNumber');
        const specialtyCodeElement = document.getElementById('specialtyCodeSelect');
        const adjustmentCategoryCodeElement = document.getElementById('adjustmentCategoryCodeSelect');
        const adjustmentCodeElement = document.getElementById('adjustmentCode');

        if (!procYrMoElement) {
            alert('Cap Process Month  not found');
            return;
        }

        if (!fundingArrangementElement) {
            alert('Funding Arrangement  not found');
            return;
        }
        if (!providerArrangementElement) {
            alert('Provider Arrangement  not found');
            return;
        }
        if (!contractArrangementElement) {
            alert('Contract Arrangement  not found');
            return;
        }
        if (!amountElement) {
            alert('Amount  not found');
            return;
        }
        if (!descriptionElement) {
            alert('Description  not found');
            return;
        }
        if (!coverageSourceSystemElement) {
            alert('Coverage Source System  not found');
            return;
        }

        if (!employerDivGroupElement) {
            alert('Employer/Div-Group  not found');
            return;
        }

        if (!hmoAdcElement) {
            alert('HMO ADC  not found');
            return;
        }

        if (!productCodeElement) {
            alert('Product Code  not found');
            return;
        }

        if (!productClassIdElement) {
            alert('Product Class ID  not found');
            return;
        }
        if (!financialMarketSegmentCodeElement) {
            alert('Financial Market Segment Code  not found');
            return;
        }
        if (!providerNetworkElement) {
            alert('Provider Network  not found');
            return;
        }
        if (!providerContractNumberElement) {
            alert('Provider Contract Number  not found');
            return;
        }
        if (!providerContractLineNumberElement) {
            alert('Provider Contract Line Number  not found');
            return;
        }
        if (!specialtyCodeElement) {
            alert('Specialty Code  not found');
            return;
        }
        if (!adjustmentCategoryCodeElement) {
            alert('Adjustment Category Code  not found');
            return;
        }
        if (!adjustmentCodeElement) {
            alert('Adjustment Code  not found');
            return;
        }

        const procYrMo = procYrMoElement.value;
        const fundingArrangement = fundingArrangementElement.value;
        const providerArrangementSelect = providerArrangementElement.value;
        const contractArrangmentSelect = contractArrangementElement.value;
        const amount = amountElement.value;
        const description = descriptionElement.value;
        const coverageSourceSystem = coverageSourceSystemElement.value;
        const employerDivGroup = employerDivGroupElement.value;
        const hmoAdcSelect = hmoAdcElement.value;
        const productCode = productCodeElement.value;
        const productClassIdSelect = productClassIdElement.value;
        const financialMarketSegmentCodeSelect = financialMarketSegmentCodeElement.value;
        const providerNetworkSelect = providerNetworkElement.value;
        const providerContractNumber = providerContractNumberElement.value;
        const providerContractLineNumber = providerContractLineNumberElement.value;
        const specialtyCodeSelect = specialtyCodeElement.value;
        const adjustmentCategoryCodeSelect = adjustmentCategoryCodeElement.value;
        const adjustmentCode = adjustmentCodeElement.value;


        if (!procYrMo) {
            alert('Cap Process Month is required');
            return;
        }

        if (!fundingArrangement) {
            alert('Funding Arrangement is required');
            return;
        }
        if (!providerArrangementSelect) {
            alert('Provider Arrangement is required');
            return;
        }
        if (!contractArrangmentSelect) {
            alert('Contract Arrangement is required');
            return;
        }
        if (!amount) {
            alert('Amount is required');
            return;
        }
        if (!description) {
            alert('Description is required');
            return;
        }
        if (!coverageSourceSystem) {
            alert('Coverage Source System is required');
            return;
        }

        if (!employerDivGroup) {
            alert('Employer/Div-Group is required');
            return;
        }

        if (!hmoAdcSelect) {
            alert('HMO ADC is required');
            return;
        }

        if (!productCode) {
            alert('Product Code is required');
            return;
        }

        if (!productClassIdSelect) {
            alert('Product Class ID is required');
            return;
        }
        if (!financialMarketSegmentCodeSelect) {
            alert('Financial Market Segment Code is required');
            return;
        }
        if (!providerNetworkSelect) {
            alert('Provider Network is required');
            return;
        }
        if (!providerContractNumber) {
            alert('Provider Contract Number is required');
            return;
        }
        if (!providerContractLineNumber) {
            alert('Provider Contract Line Number is required');
            return;
        }
        if (!specialtyCodeSelect) {
            alert('Specialty Code is required');
            return;
        }
        if (!adjustmentCategoryCodeSelect) {
            alert('Adjustment Category Code is required');
            return;
        }
        if (!adjustmentCode) {
            alert('Adjustment Code is required');
            return;
        }

        setSaveLoading(true);
        var requestBody = {
            'query_1_data': getBodyFromUI(),
            'query_2_data': {
                "USER_ID": localStorage.getItem("sub"),
                "SGN_DT": 'CURRENT_TIMESTAMP',
                "ROLE_ID": '' // ecapasso? or a number?
            },
            'data_segment_data': dataSegments
        }
        //requestBody['query_1_data']['CONTR_ARNG_CD'] = contractArrangmentSelect;
        AdjustmentsSaveApi(requestBody).then(response => {
            setSaveLoading(false);
            console.log('Response Data:', response.data);
            if (response.data.includes('successfully')) {
                const invoiceNumberMatch = response.data.match(/Invoice_number:\s*([^and]+)/);
                const newInvoiceNumber = invoiceNumberMatch ? invoiceNumberMatch[1].trim() : 'N/A';
                console.log(`Invoice#: ${newInvoiceNumber}`);
                setInvoiceNumber(newInvoiceNumber);

                const statusMatch = response.data.match(/Status:\s*'([^']+)'/);
                const newStatus = statusMatch ? statusMatch[1] : 'N/A';
                console.log(`Status: ${newStatus}`);
                setStatus(newStatus);

                setFeedback([['success', response.data]]);
            } else {
                setFeedback([['fail', response.data]]);
            }
        }).catch(error => {
            console.log('Save error:', error);
            setSaveLoading(false);
            setFeedback([['fail', 'Save Failed']]);
        });
    }

    return (
        <Box sx={{ overflow: "scroll", maxHeight: '77vh', paddingTop: 1 }}>
            {loading &&
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            }
            <Box sx={{ width: '100%', textAlign: 'center' }}>
                {feedback.map((sentence, index) => {
                    if (sentence[0] == 'success') {
                        return (
                            <Box sx={{ color: 'green' }} key={index}>{sentence[1]}</Box>
                        )
                    } else if (sentence[0] == 'fail') {
                        return (
                            <Box sx={{ color: 'red' }} key={index}>{sentence[1]}</Box>
                        )
                    }
                })}
            </Box>
            <Box sx={{ display: 'flex', mt: 1 }}>
                <Box sx={{ flex: 1, mr: 5 }}>
                    <Box sx={{ display: 'flex', mb: 0.2 }}>
                        <Typography sx={typographyStyle}><span style={{ color: 'red' }}>*</span>Type</Typography>
                        <select
                            style={selectStyle}
                            id='typeSelect'
                            onChange={(event) => { typeChange(event.target.value) }}
                        >
                            {Object.keys(typeOptions).map((option, index) =>
                                <option key={index}>{option}</option>
                            )}
                        </select>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 0.2 }}>
                        <Typography sx={typographyStyle}><span style={{ color: 'red' }}>*</span>Provider/Member</Typography>
                        <select
                            style={selectStyle}
                            id='providerMemberSelect'
                            disabled={disabledComponents['providerMemberSelect']}
                            onChange={(event) => { providerMemberChange(event.target.value) }}
                        >
                            {providerMemberOptions.map((option, index) =>
                                <option key={index}>{option}</option>
                            )}
                        </select>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 0.2 }}>
                        <Typography sx={typographyStyle}><span style={{ color: 'red' }}>*</span>Data Segment</Typography>
                        <select
                            style={selectStyle}
                            value={selectedDataSegment}
                            onChange={(event) => dataSegmentChange(event.target.value)}
                        >
                            <option key=''>---Select---</option>
                            {Object.values(dataSegments).sort().map((option) =>
                                <option key={option}>{option}</option>
                            )}
                        </select>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 0.2 }}>
                        <Typography sx={typographyStyle}><span style={{ color: 'red' }}>*</span>Cap Process Month</Typography>
                        <input
                            type='text'
                            style={{ ...inputStyle, width: '55px' }}
                            maxLength={6}
                            value={procYrMo}
                            id='procYrMo'
                            onChange={(event) => setProcYrMo(event.target.value)}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', mb: 0.2 }}>
                        <Typography sx={typographyStyle}><span style={{ color: 'red' }}>*</span>Provider Arrangement</Typography>
                        <select
                            style={selectStyle}
                            id='providerArrangementSelect'
                        >
                            <option key=''></option>
                            {Object.keys(providerArrangementOptions).map((option, index) =>
                                <option key={index}>{option}</option>
                            )}
                        </select>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 0.2 }}>
                        <Typography sx={typographyStyle}>
                            <span style={{ color: 'red' }}>*</span>Contract Arrangement
                        </Typography>
                        <select
                            style={selectStyle}
                            id='contractArrangmentSelect'
                        >
                            <option key=''></option>
                            {Object.keys(contractArrangmentOptions).map((option, index) =>
                                <option key={index}>{option}</option>
                            )}
                        </select>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 0.2 }}>
                        <Typography sx={typographyStyle}>Cap Period</Typography>
                        <input
                            id='capPeriod'
                            type='text'
                            style={{ ...inputStyle, width: '55px' }}
                            maxLength={6}
                            disabled={disabledComponents['capPeriod']}
                            value={capPeriod}
                            onChange={(event) => setCapPeriod(event.target.value)}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', mb: 0.2 }}>
                        <Typography sx={typographyStyle}>Current / Retro</Typography>
                        <select
                            style={selectStyle}
                            id='currentRetroSelect'
                            disabled={disabledComponents['currentRetroSelect']}
                        >
                            <option key=''></option>
                            {Object.values(currentRetroOptions).map((option, index) =>
                                <option key={option}>{option}</option>
                            )}
                        </select>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 0.2 }}>
                        <Typography sx={typographyStyle}><span style={{ color: 'red' }}>*</span>Amount</Typography>
                        <input type='text' style={inputStyle} id='amount' defaultValue={preloadData['AMOUNT']} />
                    </Box>
                    <Box sx={{ display: 'flex', mb: 0.2 }}>
                        <Typography sx={typographyStyle}><span style={{ color: 'red' }}>*</span>Description</Typography>
                        <textarea style={inputStyle} maxLength={255} id='description' defaultValue={preloadData['AINVDESC']} />
                    </Box>
                    <Box sx={{ display: 'flex', mb: 0.2 }}>
                        <Typography sx={typographyStyle}>Member ID</Typography>
                        <Box sx={{ display: 'flex' }}>
                            <input
                                id='memberId1'
                                type='text'
                                style={{ ...inputStyle, width: '4.5rem' }}
                                disabled={disabledComponents['memberId']}
                                defaultValue={preloadData['CUST_ID'] === 'None' ? '' : preloadData['CUST_ID']}
                            />
                            <Box sx={{ ml: 0.5, mr: 0.5 }}>-</Box>
                            <input
                                id='memberId2'
                                type='text'
                                style={{ ...inputStyle, width: '7rem' }}
                                disabled={disabledComponents['memberId']}
                                defaultValue={preloadData['MEMBER_ID'] === 'None' ? '' : preloadData['MEMBER_ID']}
                            />
                            <Box sx={{ ml: 0.5, mr: 0.5 }}>-</Box>
                            <input
                                id='memberId3'
                                type='text'
                                style={{ ...inputStyle, width: '2rem' }}
                                disabled={disabledComponents['memberId']}
                                defaultValue={preloadData['SUFFIX'] === 'None' ? '' : preloadData['SUFFIX']}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 0.2 }}>
                        <Typography sx={typographyStyle}><span style={{ color: 'red' }}>*</span>Coverage Source System</Typography>
                        <select
                            style={selectStyle}
                            id='coverageSourceSystem'
                            value={selectedCovSrcSys}
                            onChange={(event) => setSelectedCovSrcSys(event.target.value)}
                        >
                            {Object.keys(coverageSourceSystemOptions).length && <option key=''>--Select--</option>}
                            {Object.keys(coverageSourceSystemOptions).map((option, index) =>
                                <option key={index}>{option}</option>
                            )}
                        </select>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 0.2 }}>
                        <Typography sx={typographyStyle}><span style={{ color: 'red' }}>*</span>Employer/Div-Group</Typography>
                        <select
                            style={selectStyle}
                            value={selectedEmployerDivGroup}
                            id='employerDivGroup'
                            onChange={(event) => pol_id_change(event.target.value)}
                        >
                            {(employerDivGroupOptions.length == 0 && selectedDataSegment) ?
                                <option key=''>--Select--</option>
                                :
                                <option key=''></option>
                            }
                            {employerDivGroupOptions.map((option, index) =>
                                <option key={index}>{option}</option>
                            )}
                        </select>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 0.2 }}>
                        <Typography sx={typographyStyle}><span style={{ color: 'red' }}>*</span>HMO ADC</Typography>
                        <select
                            style={selectStyle}
                            id='hmoAdcSelect'
                        >
                            <option key=''>--Select--</option>
                            {Object.keys(hmoAdcOptions).map((option) =>
                                <option key={option} value={option}>{option}</option>
                            )}
                        </select>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 0.2 }}>
                        <Typography sx={typographyStyle}><span style={{ color: 'red' }}>*</span>Product Code</Typography>
                        <select
                            style={selectStyle}
                            id='productCode'
                            value={selectedProductCode}
                            onChange={(event) => setSelectedProductCode(event.target.value)}
                        >
                            {Object.keys(productCodeOptions).length == 1 ?
                                <option key=''>--Select--</option>
                                :
                                <option key=''>{temporaryProductCodeOption}</option>
                            }
                            {Object.keys(productCodeOptions).map((option, index) =>
                                <option key={index}>{option}</option>
                            )}
                        </select>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 0.2 }}>
                        <Typography sx={typographyStyle}><span style={{ color: 'red' }}>*</span>Product Class ID</Typography>
                        <select
                            style={selectStyle}
                            id='productClassIdSelect'
                        >
                            {Object.keys(productClassIdOptions).length != 1 && <option key=''></option>}
                            {Object.keys(productClassIdOptions).map((option, index) =>
                                <option key={index}>{option}</option>
                            )}
                        </select>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 0.2 }}>
                        <Typography sx={typographyStyle}><span style={{ color: 'red' }}>*</span>Financial Market Segment Code</Typography>
                        <select
                            style={selectStyle}
                            id='financialMarketSegmentCodeSelect'
                        >
                            {selectedDataSegment ?
                                <option key=''>--Select--</option>
                                :
                                <option key=''></option>
                            }
                            {Object.keys(financialMarketSegmentCodeOptions).map((option, index) =>
                                <option key={index}>{option}</option>
                            )}
                        </select>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 0.2 }}>
                        <Typography sx={typographyStyle}><span style={{ color: 'red' }}>*</span>Funding Arrangement</Typography>
                        <select
                            style={selectStyle}
                            value={selectedFundingArrangement}
                            id='fundingArrangement'
                            onChange={(event) => setSelectedFundingArrangement(event.target.value)}
                        >
                            <option key=''></option>
                            {Object.keys(fundingArrangementOptions).map((option, index) =>
                                <option key={option}>{option}</option>
                            )}
                        </select>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1.5 }}>
                        <Typography sx={typographyStyle}>Plan State of Issue</Typography>
                        <select
                            style={selectStyle}
                            value={selectedPlanStateOfIssue}
                            onChange={(event) => setSelectedPlanStateOfIssue(event.target.value)}
                        >
                            <option key=''>--Select--</option>
                            {Object.keys(planStateOfIssueOptions).map((option) =>
                                <option key={option}>{option}</option>
                            )}
                        </select>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1.5 }}>
                        <Typography sx={typographyStyle}><span style={{ color: 'red' }}>*</span>Provider Network</Typography>
                        <select
                            style={selectStyle}
                            id='providerNetworkSelect'
                        >
                            {Object.keys(providerNetworkOptions).length == 0 && <option key=''></option>}
                            {Object.keys(providerNetworkOptions).map((option, index) =>
                                <option key={index}>{option}</option>
                            )}
                        </select>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 0.7 }}>
                        <Typography sx={typographyStyle}>Prepared by</Typography>
                        <Typography sx={typographyStyle}>{preloadData['ENTERED']}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 0.7 }}>
                        <Typography sx={typographyStyle}>Reviewed by</Typography>
                        <Typography sx={typographyStyle}>{preloadData['REVIEWED']}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 0.7 }}>
                        <Typography sx={typographyStyle}>Approved by</Typography>
                        <Typography sx={typographyStyle}>{preloadData['PREAPPROVE']}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 0.7 }}>
                        <Typography sx={typographyStyle}>Director Approved by</Typography>
                        <Typography sx={typographyStyle}>{preloadData['APPROVED']}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                        <Typography sx={typographyStyle}>Sr Mgmt Approved by</Typography>
                        <Typography sx={typographyStyle}>{ }</Typography>
                    </Box>
                </Box>
                <Box sx={{ flex: 1, mr: 10 }}>
                    <Box
                        sx={{
                            borderRadius: 2,
                            padding: 1,
                            backgroundColor: useOr1 ? '#e6ffef' : 'transparent',
                            width: 'fit-content',
                            ':hover': {
                                backgroundColor: '#c9ffdd',
                                cursor: 'pointer'
                            }
                        }}
                        onClick={() => { setUseOr1(true) }}
                    >
                        <Box sx={{ display: 'flex', mb: 0.2, justifyContent: 'space-between' }}>
                            <Typography sx={typographyStyle}><span style={{ color: 'red' }}>*</span>Provider Contract #</Typography>
                            <input type='text' style={inputStyle} id='providerContractNumber' defaultValue={preloadData['CONTRACT_NUMBER']} />
                        </Box>
                        <Box sx={{ display: 'flex', mb: 0.2 }}>
                            <Typography sx={typographyStyle}><span style={{ color: 'red' }}>*</span>Provider Contract Line#</Typography>
                            <input type='text' style={inputStyle} id='providerContractLineNumber' defaultValue={preloadData['CONTRACT_LN_ID']} />
                        </Box>
                    </Box>
                    <Typography sx={{ width: '100%', paddingLeft: 20 }}>- - - - - OR - - - - -</Typography>
                    <Box
                        sx={{
                            borderRadius: 2,
                            padding: 1,
                            backgroundColor: !useOr1 ? '#e6ffef' : 'transparent',
                            width: 'fit-content',
                            ':hover': {
                                backgroundColor: '#c9ffdd',
                                cursor: 'pointer'
                            }
                        }}
                        onClick={() => {
                            if (!disabledComponents['vendorNumber']) {
                                setUseOr1(false);
                            }
                        }}
                    >
                        <Box sx={{ display: 'flex' }}>
                            <Box sx={{ flex: 1.5 }}></Box>
                            <Box sx={{ display: 'flex', flex: 1 }}>
                                <Typography sx={{ flex: 1, fontSize: '0.6rem', whiteSpace: 'nowrap', textDecoration: 'underline' }}>Data Segment</Typography>
                                <Typography sx={{ flex: 1, fontSize: '0.6rem', whiteSpace: 'nowrap', textDecoration: 'underline' }}>Payment MPIN</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', mb: 0.2 }}>
                            <Typography sx={typographyStyle}>Vendor#</Typography>
                            <Box sx={{ display: 'flex' }}>
                                <Typography sx={{ ...typographyStyle, width: 70, maxWidth: 70 }}>{preloadData['DATA_SEGMENT'] ? preloadData['DATA_SEGMENT'] : '---Select---'}</Typography>
                                <input
                                    id='vendorNumber'
                                    type='text'
                                    style={{ ...inputStyle, width: 70 }}
                                    disabled={disabledComponents['vendorNumber']}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', mb: 0.2 }}>
                            <Typography sx={typographyStyle}>Tax ID</Typography>
                            <input
                                id='taxId'
                                type='text'
                                style={inputStyle}
                                disabled={disabledComponents['taxId']}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', mb: 0.2 }}>
                            <Typography sx={typographyStyle}>Vendor Name</Typography>
                            <Typography sx={{ ...typographyStyle, width: 0 }}></Typography>
                        </Box>
                    </Box>
                    <Box sx={{ padding: 1, mt: 1 }}>
                        <Box sx={{ display: 'flex', mb: 0.2 }}>
                            <Typography sx={typographyStyle}>Address Sequence #</Typography>
                            <input
                                id='addressSequenceNumber'
                                type='text'
                                style={inputStyle}
                                disabled={disabledComponents['addressSequenceNumber']}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', mb: 1.2 }}>
                            <Typography sx={typographyStyle}>Contract Owner MPIN</Typography>
                            <input type='text' style={inputStyle} id='contractOwnerMpin' defaultValue={preloadData['CONTRACT_OWNER_MPIN']} />
                        </Box>
                        <Box sx={{ display: 'flex', mb: 0.2 }}>
                            <Typography sx={typographyStyle}>Assoc Group</Typography>
                            <input type='text' style={inputStyle} id='assocGroup' defaultValue={preloadData['AASSOCPID']} />
                        </Box>
                        <Box sx={{ display: 'flex', mb: 0.2 }}>
                            <Typography sx={typographyStyle}><span style={{ color: 'red' }}>*</span>Specialty Code</Typography>
                            <select
                                style={selectStyle}
                                id='specialtyCodeSelect'
                                value={selectedSpecialtyCode}
                                onChange={(event) => setSelectedSpecialtyCode(event.target.value)}
                            >
                                <option key=''></option>
                                {Object.keys(specialtyCodeOptions).map((option) =>
                                    <option key={option}>{option}</option>
                                )}
                            </select>
                        </Box>
                        <Box sx={{ display: 'flex', mb: 0.2 }}>
                            <Typography sx={typographyStyle}><span style={{ color: 'red' }}>*</span>Adjustment Category Code</Typography>
                            <select
                                style={selectStyle}
                                id='adjustmentCategoryCodeSelect'
                                value={selectedAdjustmentCategoryCode}
                                onChange={(event) => setSelectedAdjustmentCategoryCode(event.target.value)}
                            >
                                <option key=''></option>
                                {Object.keys(adjustmentCategoryCodeOptions).map((option) =>
                                    <option key={option}>{option}</option>
                                )}
                            </select>
                        </Box>
                        <Box sx={{ display: 'flex', mb: 0.2 }}>
                            <Typography sx={typographyStyle}><span style={{ color: 'red' }}>*</span>Adjustment Code</Typography>
                            <input type='text' style={{ ...inputStyle, width: '4rem' }} id='adjustmentCode' defaultValue={preloadData['ADJUSTMENT_CODE']} />
                        </Box>
                        <Box sx={{ display: 'flex', mb: 0.2 }}>
                            <Typography sx={typographyStyle}>Pay Now</Typography>
                            <select
                                style={selectStyle}
                                id='payNowSelect'
                                disabled={disabledComponents['payNowSelect']}
                            >
                                {Object.keys(yesNoOptions).map((option) =>
                                    <option key={option}>{option}</option>
                                )}
                            </select>
                        </Box>
                        <Box sx={{ display: 'flex', mb: 0.2 }}>
                            <Typography sx={typographyStyle}>Auto Reverse</Typography>
                            <select
                                style={selectStyle}
                                id='reverseSelect'
                                disabled={disabledComponents['reverseSelect']}
                            >
                                {Object.keys(yesNoOptions).map((option) =>
                                    <option key={option}>{option}</option>
                                )}
                            </select>
                        </Box>
                        <Box sx={{ display: 'flex', mb: 0.2 }}>
                            <Typography sx={typographyStyle}>Extension</Typography>
                            <select
                                style={selectStyle}
                                id='extensionSelect'
                            >
                                {extensionOptions.map((option, index) =>
                                    <option key={index}>{option}</option>
                                )}
                            </select>
                        </Box>
                        <Box sx={{ display: 'flex', mb: 1.7 }}>
                            <Typography sx={typographyStyle}>Backup File Path</Typography>
                            <Typography sx={typographyStyle}>{preloadData['ABCKFLPTH']}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', mb: 1.2 }}  >
                            <Typography sx={typographyStyle}>Invoice#</Typography>
                            <Typography sx={typographyStyle}>{invoiceNumber}</Typography>
                            <Typography sx={typographyStyle}>{preloadData['INVOICE_NUMBER']}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', mb: 1.2 }}>
                            <Typography sx={typographyStyle}>Status</Typography>
                            <Typography sx={typographyStyle}>{status}</Typography>
                            <Typography sx={typographyStyle}>{preloadData['STATUS']}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', mb: 0.5 }}>
                            <Typography sx={typographyStyle}>Prepared date</Typography>
                            <Typography sx={typographyStyle}></Typography>
                        </Box>
                        <Box sx={{ display: 'flex', mb: 0.5 }}>
                            <Typography sx={typographyStyle}>Reviewed date</Typography>
                            <Typography sx={typographyStyle}></Typography>
                        </Box>
                        <Box sx={{ display: 'flex', mb: 0.5 }}>
                            <Typography sx={typographyStyle}>Approved date</Typography>
                            <Typography sx={typographyStyle}></Typography>
                        </Box>
                        <Box sx={{ display: 'flex', mb: 0.5 }}>
                            <Typography sx={typographyStyle}>Director Approved date</Typography>
                            <Typography sx={typographyStyle}></Typography>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Typography sx={typographyStyle}>Sr Mgmt Approved date</Typography>
                            <Typography sx={typographyStyle}></Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {addOrUpdate === 'add' ?
                <Box sx={{ whiteSpace: 'nowrap' }}>
                    <Button
                        variant='contained'
                        size='small'
                        sx={{ mr: 1 }}
                        disabled={addOpenApiLoading || loading}
                        onClick={() => save()}
                    >
                        Save
                    </Button>
                    <Button
                        variant='contained'
                        size='small'
                        sx={{ mr: 1 }}
                        disabled={addOpenApiLoading || loading}
                    >
                        Save & Copy
                    </Button>
                    <Button
                        variant='contained'
                        size='small'
                        sx={{ mr: 1 }}
                        onClick={() => resetAll()}
                    >
                        Clear
                    </Button>
                    <Button
                        variant='contained'
                        size='small'
                        sx={{ mr: 1 }}
                        onClick={() => switchTab(0)}
                    >
                        Cancel
                    </Button>
                </Box> :
                <Box sx={{ whiteSpace: 'nowrap' }}>
                    <Button
                        variant='contained'
                        size='small'
                        sx={{ mr: 1 }}
                        onClick={() => update()}
                        disabled={addOpenApiLoading || loading || updateLoading}
                    >
                        Update
                        {updateLoading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Button>
                    <Button
                        variant='contained'
                        size='small'
                        sx={{ mr: 1 }}
                        onClick={() => handleDelete()}
                        disabled={addOpenApiLoading || loading || deleteLoading}
                    >
                        Delete
                        {deleteLoading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Button>
                    <Button
                        variant='contained'
                        size='small'
                        sx={{ mr: 1 }}
                        onClick={() => switchTab(0)}
                    >
                        Cancel
                    </Button>
                </Box>
            }
        </Box >
    );
}
