import { Grid } from "@mui/material";
import ParametersSeachForm from "./ParametersSearchForm";
import ParametersSeachTable from "./ParametersSearchTable";
import React from "react";

export const ParametersSeach = () => {
    const [showTable, setShowTable] = React.useState(false);
    const [tableData, setTableData] = React.useState([]);
    const [forceCapType, setForceCapType] = React.useState('');
    const [dataSegment, setDataSegment] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    

    const handleSearch = (data) => {
        setTableData(data);
    };

    const handleReset = (resetValue) => {
        setShowTable(resetValue);
    };

    const handleForceCapType = (forceCapType) => {
        setForceCapType(forceCapType);
    }

    const handleDataSegment = (dataSegment) => {
        setDataSegment(dataSegment);
    }

    const handleError = (error) => {
        setErrorMessage(error);
    }

    React.useEffect(() => {
        if (tableData !== undefined) {
            if (tableData != [] && tableData.length > 0) {
                setShowTable(true);
            } else {
                setShowTable(false);
            }
        }
    }, [tableData]);

    return (
        <Grid container spacing={2} style={{ margin: '15px 0px 0px 0px' }}>
            <Grid item xs={12} sm={4} md={3} lg={2.5}>
                <ParametersSeachForm onSearch={handleSearch} onReset={handleReset} passForceCapType={handleForceCapType} passDataSegment={handleDataSegment} passError={handleError}/>
            </Grid>
            <Grid item xs={12} sm={8} md={9} lg={9.5} pr={2}>
                <ParametersSeachTable showTable={showTable} inputTableData={tableData} inputForceCapType={forceCapType} inputDataSegment={dataSegment} inputError={errorMessage}/>
            </Grid>
        </Grid>
    );
};
export default ParametersSeach;