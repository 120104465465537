import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import SendIcon from "@mui/icons-material/Send";
import { getToken } from "../../TokenAPI.js";
import { Button, Box, LinearProgress } from "@mui/material";
import { HelpButtons } from '../../../components/helpPrintButtons.js';

import {
  getThresholdsApi,
  memberRolesAxiosClient,
  memberRolesJsonSchema,
  memberRolesUpdateAxiosClient,
  updateThresholdApi,
} from "./rolesApi";
import { updateCurrentRoleMap } from "./redux/rolesSlice";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Skeleton,
} from "@mui/material";
import {
  rolesMap,
  rolesPermissionMap,
  rolesValueMap,
  rolesPermissionDefaultMap,
} from "./rolesConstants";
import { useDispatch, useSelector } from "react-redux";

export const AdminRoles = () => {
  const bearerToken = getToken();
  const headers = {
    'Authorization': `Bearer ${bearerToken}`
  }
  const [thresholds, setThresholds] = useState({});
  const [thresholdValue, setThresholdValue] = useState('');
  const [selectRole, setSelectRole] = useState("");
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(false);
  const [update, setUpdate] = useState(false);
  const [del, setDel] = useState(false);
  const [add, setAdd] = useState(false);
  const [change, setChange] = useState({});
  const permissions = useSelector((store) => store.user.permissions);
  const updatePermission =
    permissions.admin_roles.update === "Y" ? true : false;

  const tempMap = { ...rolesPermissionMap };
  const [rolesAccessMap, setRolesAccessMap] = useState(tempMap);
  const handleSelectRoleChange = (event) => {
    setThresholdValue(thresholds[rolesMap[event.target.value]]);
    setLoading(true);
    setSelectRole(rolesMap[event.target.value]);
  };
  const checkForChanges = (map1, map2) => {
    console.log(map1, map2);
    const swappedRolesValueMap = Object.entries(rolesValueMap).reduce(
      (acc, [key, value]) => {
        acc[value] = key;
        return acc;
      },
      {}
    );
    const updatedChanges = {};

    Object.keys(map1).forEach((row) => {
      Object.keys(map1[row]).forEach((field) => {
        if (map1[row][field] !== map2[row][field]) {
          if (!updatedChanges[swappedRolesValueMap[row]]) {
            updatedChanges[swappedRolesValueMap[row]] = {};
          }
          updatedChanges[swappedRolesValueMap[row]][field] = map1[row][field]
            ? "N"
            : "Y";
        }
      });
    });
    setChange(updatedChanges);
    Object.keys(updatedChanges).map((key) => {
      const body = {
        role: selectRole,
        screen_name: key,
        value: updatedChanges[key],
      };
      memberRolesUpdateAxiosClient.post("", body, headers).then((response) => {
        console.log("query success");
      });
    });
    // Do something with the updatedChanges, such as logging or updating state
  };

  const refresh = () => {
    setLoading(true);
    // setRolesAccessMap(originalRolesAccessMap);
    setLoading(false);
  };
  useEffect(() => {
    if (selectRole !== "") {
      var roles = selectRole;
      const requestBody = { ...memberRolesJsonSchema };
      requestBody.role = [selectRole];
      memberRolesAxiosClient
        .post("", requestBody, headers)
        .then((response) => {
          const data = response.data;
          var tempRolesMap = { ...rolesPermissionMap };
          Object.keys(data).map((screenName) => {
            Object.keys(data[screenName]).map((action) => {
              const value = data[screenName][action];
              const permission = screenName;
              const field = action;
              if (value === "Y")
                tempRolesMap[rolesValueMap[permission]][field] = true;
              else tempRolesMap[rolesValueMap[permission]][field] = false;
            });
          });
          setRolesAccessMap(tempRolesMap);
          setLoading(false);
          setAdd(false);
          setChange({});
          setUpdate(false);
          setView(false);
          setDel(false);
        })
        .catch((error) => {
          if (error.response && error.response.status === 500) {
            console.log("error logging you in ");
          }
        });
    } else {
      setRolesAccessMap(rolesPermissionMap);
      // setOriginalRolesAccessMap(rolesPermissionMap);
      setLoading(false);
    }
  }, [selectRole]);

  useEffect(() => {
    getThresholdsApi().then(response => {
      // console.log(response);
      setThresholds(response.data);
    }).catch(error => {
      console.log(error);
    })
  }, [selectRole]);

  const roleOptions = [
    "Select",
    "ECap Admin",
    "Non ECap",
    "ECap Associate",
    "ECap Analyst",
    "ECap Lead",
    "Supervisor",
    "Manager",
    "Director",
    "Senior Management",
  ];

  const rows = {
    "ADMIN (ALL)": [
      "Roles",
      "Codes",
      "Group Contracts",
      "Exclude Contracts",
      "Approval",
    ],
    "MAINTENANCE (All)": [
      "Cap/Payment Adjustments",
      "Retro Period Control",
      "Premium Factors",
      "Benefit Factors",
      "Reporting Overrides",
      "Force Cap Parameters",
    ],
    "MEMBER (All)": ["History", "Member Search"],
    "PROVIDER (All)": [
      "Contract",
      "Demographics",
      "Provider History",
      "CRT",
      "Association",
    ],
    ERRORS: [],
    "JOB SCHEDULING": [],
    "Reports (All)": [
      "Member",
      "Benefit",
      "Provider",
      "ECapProcess",
      "Payment",
      "Premium",
    ],
  };
  const changeRolesMap = (event) => {
    const id = event.target.id;
    const action = event.target.name;
    const updatedMap = { ...rolesAccessMap };
    var flag = false;
    if (action === "view") {
      updatedMap[id].view = !rolesAccessMap[id].view;
      Object.keys(rows).map((row) => {
        if (row === id) {
          rows[row].map((subRow) => {
            updatedMap[subRow].view = updatedMap[id].view;
          });
          flag = true;
        }
      });
      if (flag === false) {
        Object.keys(rows).map((row) => {
          rows[row].map((subRow) => {
            if (subRow === id) {
              if (updatedMap[subRow].view)
                updatedMap[row].view = updatedMap[subRow].view;
            }
          });
        });
      }
    }
    if (action === "update") {
      updatedMap[id].update = !rolesAccessMap[id].update;
      if (updatedMap[id].update) updatedMap[id].view = true;
      Object.keys(rows).map((row) => {
        if (row === id) {
          rows[row].map((subRow) => {
            updatedMap[subRow].update = updatedMap[id].update;
            if (updatedMap[subRow].update) updatedMap[subRow].view = true;
          });
          flag = true;
        }
      });
      if (flag === false) {
        Object.keys(rows).map((row) => {
          rows[row].map((subRow) => {
            if (subRow === id) {
              if (updatedMap[subRow].update)
                updatedMap[row].update = updatedMap[subRow].update;
              if (updatedMap[row].update) updatedMap[row].view = true;
            }
          });
        });
      }
    } else if (action === "add") {
      updatedMap[id].add = !rolesAccessMap[id].add;
      if (updatedMap[id].add) updatedMap[id].view = true;
      Object.keys(rows).map((row) => {
        if (row === id) {
          rows[row].map((subRow) => {
            updatedMap[subRow].add = updatedMap[id].add;
            if (updatedMap[subRow].add) updatedMap[subRow].view = true;
          });
          flag = true;
        }
      });
      if (flag === false) {
        Object.keys(rows).map((row) => {
          rows[row].map((subRow) => {
            if (subRow === id) {
              if (updatedMap[subRow].add)
                updatedMap[row].add = updatedMap[subRow].add;
              if (updatedMap[row].add) updatedMap[row].view = true;
            }
          });
        });
      }
    } else if (action === "delete") {
      updatedMap[id].delete = !rolesAccessMap[id].delete;
      if (updatedMap[id].delete) updatedMap[id].view = true;
      Object.keys(rows).map((row) => {
        if (row === id) {
          rows[row].map((subRow) => {
            updatedMap[subRow].delete = updatedMap[id].delete;
            if (updatedMap[subRow].delete) updatedMap[subRow].view = true;
          });
          flag = true;
        }
      });
      if (flag === false) {
        Object.keys(rows).map((row) => {
          rows[row].map((subRow) => {
            if (subRow === id) {
              if (updatedMap[subRow].delete)
                updatedMap[row].delete = updatedMap[subRow].delete;
              if (updatedMap[row].delete) updatedMap[row].view = true;
            }
          });
        });
      }
    }
    setRolesAccessMap(updatedMap);
  };
  const changeSelectAll = (event) => {
    const action = event.target.name;
    const updatedMap = { ...rolesAccessMap };

    if (
      action === "view" ||
      action === "update" ||
      action === "add" ||
      action === "delete"
    ) {
      var flag = false;
      if (action === "view") {
        flag = !view;
        setView(!view);
      } else if (action === "update") {
        flag = !update;
        setUpdate(!update);
      } else if (action === "add") {
        flag = !add;
        setAdd(!add);
      } else if (action === "delete") {
        flag = !del;
        setDel(!del);
      }

      Object.entries(rows).forEach(([row, subRows]) => {
        subRows.forEach((subRow) => {
          updatedMap[subRow][action] = flag;
        });
        updatedMap[row][action] = flag;
      });
    } else if (action === "all") {
      Object.entries(rows).forEach(([row, subRows]) => {
        subRows.forEach((subRow) => {
          updatedMap[subRow].view = true;
          updatedMap[subRow].update = true;
          updatedMap[subRow].add = true;
          updatedMap[subRow].delete = true;
        });
        updatedMap[row].view = true;
        updatedMap[row].update = true;
        updatedMap[row].add = true;
        updatedMap[row].delete = true;
      });

      setView(true);
      setUpdate(true);
      setAdd(true);
      setDel(true);
    }
    setRolesAccessMap(updatedMap);
  };
  const makeUpdate = () => {
    if (selectRole !== "") {
      setLoading(true);
      var roles = selectRole;
      const requestBody = { ...memberRolesJsonSchema };
      requestBody.role = [selectRole];
      memberRolesAxiosClient
        .post("", requestBody, headers)
        .then((response) => {
          const data = response.data;
          var tempRolesMap = { ...rolesPermissionDefaultMap };
          Object.keys(data).map((screenName) => {
            Object.keys(data[screenName]).map((action) => {
              const value = data[screenName][action];
              const permission = screenName;
              const field = action;
              if (value === "Y")
                tempRolesMap[rolesValueMap[permission]][field] = true;
              else tempRolesMap[rolesValueMap[permission]][field] = false;
            });
          });
          checkForChanges(tempRolesMap, rolesAccessMap);

          var tempRequestBody = {
            "new_threshold": thresholdValue,
            "user_id": localStorage.getItem('sub'),
            "role_name": selectRole
          }
          updateThresholdApi(tempRequestBody).then(response => {
            console.log(response);
            setLoading(false);
          }).catch(error => {
            console.log(error);
          });
        })
        .catch((error) => {
          if (error.response && error.response.status === 500) {
            console.log("error logging you in ");
          }
        });
    }
  }
  return (
    <Box id='reportsMainTable' sx={{ width: "100%", maxHeight: "87vh", overflowY: "auto" }}>
      <div style={{ position: 'absolute', top: '0', right: '20px', display: 'flex' }}>
        <HelpButtons index={0}/>
      </div>
      <Box sx={{ position: 'relative', mt: "1rem" }}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Typography sx={{ color: "red", mr: "0.1rem" }}>*</Typography>
          <Typography sx={{ mr: "1rem", fontWeight: "bold" }}>
            Select Role
          </Typography>
          <select onChange={handleSelectRoleChange}>
            {roleOptions.map((role, index) => (
              <option key={index}>{role}</option>
            ))}
          </select>
        </Box>
      </Box>
      <Table
        sx={{ width: "100%", mt: "1rem" }}
        stickyHeader
      >
        <TableHead>
          <TableRow>
            <TableCell style={{ backgroundColor: "#b0c0da", width: "40%" }}>
              Available Online Module
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "#b0c0da",
                width: "5%",
                textAlign: "center",
              }}
            >
              View
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "#b0c0da",
                width: "5%",
                textAlign: "center",
              }}
            >
              Update
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "#b0c0da",
                width: "5%",
                textAlign: "center",
              }}
            >
              Add
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "#b0c0da",
                width: "5%",
                textAlign: "center",
              }}
            >
              Delete
            </TableCell>
            <TableCell
              style={{ backgroundColor: "#b0c0da", width: "40%" }}
            ></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(rows).map((mainRow) => (
            <React.Fragment key={mainRow}>
              <TableRow>
                <TableCell>{mainRow}</TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {loading ? (
                    <Skeleton
                      animation='wave'
                      variant='text'
                    />
                  ) : (
                    <input
                      type='checkbox'
                      defaultChecked={rolesAccessMap[mainRow].view}
                      id={mainRow}
                      name='view'
                      onClick={changeRolesMap}
                    ></input>
                  )}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {loading ? (
                    <Skeleton
                      animation='wave'
                      variant='text'
                    />
                  ) : (
                    <input
                      type='checkbox'
                      defaultChecked={rolesAccessMap[mainRow].update}
                      id={mainRow}
                      name='update'
                      onClick={changeRolesMap}
                    ></input>
                  )}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {loading ? (
                    <Skeleton
                      animation='wave'
                      variant='text'
                    />
                  ) : (
                    <input
                      type='checkbox'
                      defaultChecked={rolesAccessMap[mainRow].add}
                      id={mainRow}
                      name='add'
                      onClick={changeRolesMap}
                    ></input>
                  )}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {loading ? (
                    <Skeleton
                      animation='wave'
                      variant='text'
                    />
                  ) : (
                    <input
                      type='checkbox'
                      defaultChecked={rolesAccessMap[mainRow].delete}
                      id={mainRow}
                      name='delete'
                      onClick={changeRolesMap}
                    ></input>
                  )}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
              {rows[mainRow].map((subRow, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ width: "1rem" }}></div>
                    {subRow}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {loading ? (
                      <Skeleton
                        animation='wave'
                        variant='text'
                      />
                    ) : (
                      <input
                        type='checkbox'
                        defaultChecked={rolesAccessMap[subRow].view}
                        id={subRow}
                        name='view'
                        onClick={changeRolesMap}
                      ></input>
                    )}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {loading ? (
                      <Skeleton
                        animation='wave'
                        variant='text'
                      />
                    ) : (
                      <input
                        type='checkbox'
                        defaultChecked={rolesAccessMap[subRow].update}
                        id={subRow}
                        name='update'
                        onClick={changeRolesMap}
                      ></input>
                    )}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {loading ? (
                      <Skeleton
                        animation='wave'
                        variant='text'
                      />
                    ) : (
                      <input
                        type='checkbox'
                        defaultChecked={rolesAccessMap[subRow].add}
                        id={subRow}
                        name='add'
                        onClick={changeRolesMap}
                      ></input>
                    )}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {loading ? (
                      <Skeleton
                        animation='wave'
                        variant='text'
                      />
                    ) : (
                      <input
                        type='checkbox'
                        defaultChecked={rolesAccessMap[subRow].delete}
                        id={subRow}
                        name='delete'
                        onClick={changeRolesMap}
                      ></input>
                    )}
                  </TableCell>
                  <TableCell>
                    {subRow === 'Cap/Payment Adjustments' &&
                      <input
                        style={{ height: '20px', margin: 0, padding: 0 }}
                        placeholder="(enter threshold Value)"
                        value={thresholdValue}
                        onChange={(event) => setThresholdValue(event.target.value)}
                      ></input>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </React.Fragment>
          ))}
          <TableRow>
            <TableCell sx={{ textAlign: "right", fontWeight: "bold" }}>
              Select All
            </TableCell>
            <TableCell sx={{ textAlign: "center" }}>
              <input
                type='checkbox'
                onClick={changeSelectAll}
                name='view'
                defaultChecked={view}
              ></input>
            </TableCell>
            <TableCell sx={{ textAlign: "center" }}>
              <input
                type='checkbox'
                onClick={changeSelectAll}
                name='update'
                defaultChecked={update}
              ></input>
            </TableCell>
            <TableCell sx={{ textAlign: "center" }}>
              <input
                type='checkbox'
                onClick={changeSelectAll}
                defaultChecked={add}
                name='add'
              ></input>
            </TableCell>
            <TableCell sx={{ textAlign: "center" }}>
              <input
                type='checkbox'
                onClick={changeSelectAll}
                defaultChecked={del}
                name='delete'
              ></input>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ textAlign: "right", fontWeight: "bold" }}>
              Full Access
            </TableCell>
            <TableCell sx={{ textAlign: "center" }}>
              <input
                onClick={changeSelectAll}
                name='all'
                type='checkbox'
              ></input>
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Box
        display='flex'
        gap={3}
        sx={{ display: "flex", flexDirection: "row" }}
      >
        <Button
          id='save-button'
          variant='contained'
          endIcon={<SendIcon />}
          disabled={!updatePermission}
          onClick={makeUpdate}
        >
          Save
        </Button>

        <Button
          id='cancel-button'
          variant='contained'
          endIcon={<SendIcon />}
          onClick={refresh}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};
