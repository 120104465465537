import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import { premiumViewDownloadApi } from './premiumViewDownloadApi';
import { CMSApi } from "./CMSApi";
import { HelpButtons } from '../../../components/helpPrintButtons.js';


function createData(reportID, reportName) {
    return { reportID, reportName };
}

const rows = [
    createData('EC0600CP', 'MMR-VS-PPR'),
    createData('EC0610CP', 'CMS PREMIUM BY CONTRACT BY MONTH'),
    createData('EC0620CP', 'CMS RECONCILIATION PPR-VS-BASECAP'),
];

const viewButtonV = [true, false, true];
const downloadButtonV = [false, true, false];

const MainTable = ({ inputCMSID, viewButtonV, downloadButtonV }) => {
    const [viewButtonVisibility] = React.useState(viewButtonV);
    const [downloadButtonVisibility] = React.useState(downloadButtonV);
    const [CMSID, setCMSID] = useState(['']);
    useEffect(() => {
        if (inputCMSID !== undefined) {
            setCMSID(['', ...inputCMSID]);
        }
    }, [inputCMSID]);

    function buttonAction(reportID, viewDownload, type) {
        var requestBody = {
            "report_id":reportID,
            "cms":'',
            "proc_yr_mo":'',
            "type":type
        }
        if (reportID === 'EC0600CP') {
            const cms = document.getElementById(`${reportID}CMSID`);
            requestBody['cms'] = cms.value;
        }
        const pym = document.getElementById(`${reportID}ProcYrMo`)
        requestBody['proc_yr_mo'] = pym.value;
        premiumViewDownloadApi(requestBody).then(response => {
            console.log(response);
            if (response.data.size !== 0) {
                if (viewDownload === "view") {
                    // Create a blob URL for the PDF file  
                    const blobUrl = URL.createObjectURL(response.data);
                    // Open the PDF file in a new browser tab  
                    const newTab = window.open(blobUrl, 'new', 'width=1000,height=1000,resizable=yes,status=yes');
                    newTab.focus();
                } else if (viewDownload === "download") {
                    // Create a link element with the PDF file  
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(response.data);
                    link.download = `${reportID}.csv`;  
                    link.click();
                }
            }
        }).catch(error => {
            console.log(error);
        });
    }

    return (
        <div id='reportsMainTable' style={{ overflowY: 'auto' }}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ backgroundColor: "#b0c0da" }}>Report ID</TableCell>
                            <TableCell style={{ backgroundColor: "#b0c0da" }}>Report Name</TableCell>
                            <TableCell style={{ backgroundColor: "#b0c0da" }}>CMS Contract</TableCell>
                            <TableCell style={{ backgroundColor: "#b0c0da" }}>Proc Yr Mo</TableCell>
                            <TableCell style={{ textAlign: 'center', backgroundColor: "#b0c0da" }}>View</TableCell>
                            <TableCell style={{ textAlign: 'center', backgroundColor: "#b0c0da" }}>Download</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow
                                key={row.reportID}
                            >
                                <TableCell>{row.reportID}</TableCell>
                                <TableCell>{row.reportName}</TableCell>
                                <TableCell>
                                {index === 0 ?  
                                <select 
                                style={{ width: '70px' }}
                                id={`${row.reportID}CMSID`}
                                >  
                                    {CMSID.map((option) => (  
                                    <option value={option}>{option}</option>  
                                    ))}  
                                </select>  
                                :  
                                <select 
                                style={{ width: '70px' }}
                                id={`${row.reportID}CMSID`}
                                >  
                                    {['', 'ALL'].map((option) => (  
                                    <option value={option}>{option}</option>  
                                    ))}  
                                </select>
                                } 
                                </TableCell>
                                <TableCell>
                                    <input
                                        type="text" 
                                        maxLength="6" 
                                        style={{ width: "5rem" }}
                                        id={`${row.reportID}ProcYrMo`}
                                    ></input>
                                </TableCell>
                                <TableCell style={{ textAlign: 'center' }}>
                                    {viewButtonVisibility[index] ? (
                                        <Button  
                                        style={{ textTransform: 'none' }}
                                        onClick={() => buttonAction(row.reportID, 'view', 'pdf')}
                                        >
                                            View
                                        </Button>
                                    ) : null}
                                </TableCell>
                                <TableCell style={{ textAlign: 'center' }}>
                                    {downloadButtonVisibility[index] ? (
                                        <Button  
                                        style={{ textTransform: 'none' }}
                                        onClick={() => buttonAction(row.reportID, 'download', 'csv')}
                                        >
                                            Download
                                        </Button>
                                    ) : null}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export const ReportsPremium = ({inputCMSID}) => {
    const [CMSID, setCMSID] = useState();
    useEffect(() => {
        CMSApi().then(response => {
            setCMSID(response.data.CMS_CONTR_ID);
        }).catch(error => {
            console.log("There was an error calling Premium API");
        });
    }, [inputCMSID]);
    return (
        <div style={{ width: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '5px', marginTop: '8px' }}>
                <HelpButtons index={4} />
            </div>
            <div>
                <MainTable inputCMSID={CMSID} viewButtonV={viewButtonV} downloadButtonV={downloadButtonV} />
            </div>
        </div>
    );
}; 