import { useState, useEffect } from 'react';
import { Box } from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Grid } from "@mui/material";
import Upload from './Upload/Upload';
import { SearchControl } from "./Search/SearchControl";
import { SearchUpdateAdd } from './Search/SearchUpdateAdd';
import { getDataSegmentsDict } from "../../Home/dataSegmentApi";
import { Typography, Breadcrumbs, Link } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { HelpButtons } from '../../../components/helpPrintButtons.js';


const sections = [
	"Search",
	"Add",
	"Upload",
];


export const MaintenanceAdjustments = () => {
	const [preloadData, setPreloadData] = useState({});
	const [dataSegments, setDataSegments] = useState([]);
	const [value, setValue] = useState(0);

	useEffect(() => {
		setDataSegments(getDataSegmentsDict());
	}, []);

	function handleInvoiceClick(data) {
		setPreloadData(data);
		setValue(-1);
	}
	// only adjust component if value changes
	const MakeTabComponent = () => {
		const [currentTab, setCurrentTab] = useState(null);
		useEffect(() => {
			setCurrentTab(makeTabComponent(value));
		}, [value]);
		return currentTab;
	};
	// actual components to show depending on tab value
	const makeTabComponent = (value) => {
		if (value === -1) {
			return (
				<SearchUpdateAdd
					inputDataSegments={dataSegments}
					inputData={preloadData}
					addOrUpdate={'update'}
					switchTab={setValue}
				/>
			)
		}
		if (value === 0) {
			return (
				<SearchControl
					handleInvoiceClick={handleInvoiceClick}
					inputPreloadData={preloadData}
					inputDataSegments={dataSegments}
				/>
			);
		}
		if (value === 1) {
			return (
				<SearchUpdateAdd
					inputDataSegments={dataSegments}
					inputData={{}}
					addOrUpdate={'add'}
					switchTab={setValue}
				/>
			)
		}
		if (value === 2) {
			return <Upload switchTab={setValue} />
		}
	};

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
        <Grid 
		id='reportsMainTable'
		container spacing={1} 
		style={{ margin: '0px 0px', width:"100%" }}
		>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                    <Typography color="inherit">Maintenance</Typography>
                    <Typography color="navy">Cap/Payment Adjustments</Typography>
                </Breadcrumbs>
				<HelpButtons index={1}/>
			</Grid>
            <Grid item xs={12} style={{ marginTop: '0px' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    sx={{ "& .MuiTabs-indicator": { backgroundColor: "#002153" }, pt: 1.5 }}
                >
                    {sections.map((section, index) => (
                        <Tab key={index} label={section} />
                    ))}
                </Tabs>
                <MakeTabComponent />
            </Grid>
        </Grid>
    );
};
export default MaintenanceAdjustments;
