import { Box, Typography, Select, MenuItem, TextField, Button } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import SettingsIcon from '@mui/icons-material/Settings';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ReplayIcon from '@mui/icons-material/Replay';
import FilterListIcon from '@mui/icons-material/FilterList';
import Tooltip from '@mui/material/Tooltip';
import { KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material';
import { useEffect, useState } from "react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { formatYearMonth } from '../../../helperFunctions';
import { AdjustmentsSearchApi } from '../AdjustmentsAPI';
import { LinearProgress } from "@material-ui/core";
import { SearchTable } from "./SearchTable";
import { testPDSeg } from "../../../testPopupDataSegment";
import { getDataSegmentIntFromString } from "../../../Home/dataSegmentApi";


export const Search = ({
	inputDataSegments,
	handleInvoiceClick
}) => {
	const [showSearch, setShowSearch] = useState(true);
	const [loading, setLoading] = useState(false);
	const [tableData, setTableData] = useState([]);
	const [noRecordsFound, setNoRecordsFound] = useState(false);
	const [selectedDataSegment, setSelectedDataSegment] = useState('');
	const [dataSegments, setDataSegments] = useState([]);
	const [selectedType, setSelectedType] = useState('');
	const [typeOptions, setTypeOptions] = useState([
		'ALL',
		'CAP ADJ',
		'PAYMENT ADJ'
	]);
	const [selectedStatus, setSelectedStatus] = useState('');
	const [statusOptions, setStatusOptions] = useState([
		'APPROVED',
		'ENTERED',
		'FAILED',
		'INTERFACED',
		'OPEN',
		'REAPPROVE',
		'REVIEWED'
	]);
	const [processMonthFrom, setProcessMonthFrom] = useState(null);
	const [processMonthTo, setProcessMonthTo] = useState(null);

	useEffect(() => {
		if (inputDataSegments) {
			setDataSegments(inputDataSegments);
		}
	}, [inputDataSegments])

	function openPopupDataSegment() {
        testPDSeg('data-seg-select');
        window.onmessage = (event) => {
            try {
                var response = event.data.split(' ');
				setSelectedDataSegment(getDataSegmentIntFromString(response[1]));
            } catch {
                console.log('popup receive message error');
            }
        };
    }

	function handleReset() {
		setSelectedDataSegment('');
		setSelectedType('');
		setNoRecordsFound(false);
		setSelectedStatus('');
		setProcessMonthFrom(null);
		setProcessMonthTo(null);
		document.getElementById('adjustment-code').value = '';
		document.getElementById('amount-from').value = '';
		document.getElementById('amount-to').value = '';
		document.getElementById('approved').value = '';
		document.getElementById('contract-line').value = '';
		document.getElementById('contract-id').value = '';
		document.getElementById('prepared').value = '';
		document.getElementById('reviewed').value = '';
		document.getElementById('ssmk').value = '';
	}

	function toggleSearch() {
		setShowSearch(!showSearch);
	}

	function handleSubmit() {
		// validations
		var validInputs = true;
		// search
		if (validInputs) {
			setLoading(true);
			var requestBody = {
				"DATA_SEGMENT": selectedDataSegment,
				"TYPE": selectedType.replace(/\s+/g, ''),
				"PROC_YR_MONTH_FROM": formatYearMonth(processMonthFrom),
				"PROC_YR_MONTH_TO": formatYearMonth(processMonthTo),
				"ADJUSTMENT_CODE": document.getElementById('adjustment-code').value,
				"AMOUNT_FROM": document.getElementById('amount-from').value,
				"AMOUNT_TO": document.getElementById('amount-to').value,
				"APPROVED": document.getElementById('approved').value,
				"CONTRACT_LN_ID": document.getElementById('contract-line').value,
				"CONTRACT_NUMBER": document.getElementById('contract-id').value,
				"ENTERED": document.getElementById('prepared').value,
				"REVIEWED": document.getElementById('reviewed').value,
				"STATUS": selectedStatus,
				"SRC_SYSTEM_MEMBER_KEY": document.getElementById('ssmk').value,
			}
			// console.log(requestBody);
			AdjustmentsSearchApi(requestBody).then(response => {
				setLoading(false);
				// console.log(response);
				if (response.data.length > 0) {
					setTableData(response.data);
					setNoRecordsFound(false);
				} else {
					setTableData([]);
					setNoRecordsFound(true);
				}
			}).catch(error => {
				setLoading(false);
				console.log(error);
			})
		}
	}

	return (
		<Box style={{ height: '100%', display: 'flex', flexDirection: 'row', overflowX: 'hidden' }}>
			{showSearch &&
				<Box sx={{ flexDirection: 'column', width: '315px', display: 'block' }}>
					<Box sx={{ display: 'flex', flexDirection: 'row', width: 315, justifyContent: 'flex-end', alignItems: 'right', ml: 1, mt: 1, mb: 1 }}>
						<Tooltip title="Advanced Filters">
							<IconButton>
								<FilterListIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title="Settings">
							<IconButton>
								<SettingsIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title="Saved Search">
							<IconButton>
								<BookmarkIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title="Reset All">
							<IconButton onClick={() => handleReset()}>
								<ReplayIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title="Minimize Search">
							<IconButton onClick={() => toggleSearch()}>
								<KeyboardArrowLeft />
							</IconButton>
						</Tooltip>
					</Box>
					<Box sx={{ maxHeight: '65vh', overflowY: 'scroll' }}>
						<Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
							<Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>Data Segment<span style={{ color: 'red' }}>*</span></Typography>
							<Box sx={{ flex: 2, minWidth: 80, maxWidth: 136 }}>
								<Select
									size='small'
									required
									onChange={(event) => setSelectedDataSegment(event.target.value)}
									style={{ width: '100%' }}
									value={selectedDataSegment}
								>
									{Object.keys(dataSegments).sort((a, b) => dataSegments[a].localeCompare(dataSegments[b])).map((option) => (
										<MenuItem
											size='small'
											value={option}
											key={option}
										>
											{dataSegments[option]}
										</MenuItem>
									))}
								</Select>
							</Box>
							<div
								style={{
									height: '40px',
									width: '40px',
									border: '#d3d3d3 1px solid',
									borderRadius: '3px',
									textAlign: 'center',
									alignContent: 'center',
									marginLeft: '0.2rem'
								}}
								onClick={() => openPopupDataSegment()}
							>
								<img
									style={{ height: '80%', width: '80%' }}
									src="Binoculars-icon.png"
									title="Filtering Data segment"
								></img>
							</div>
						</Box>
						<Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
							<Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>Type<span style={{ color: 'red' }}>*</span></Typography>
							<Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
								<Select
									size='small'
									required
									onChange={(event) => setSelectedType(event.target.value)}
									style={{ width: '100%' }}
									value={selectedType}
								>
									{typeOptions.map((option) => (
										<MenuItem
											value={option}
											key={option}
										>
											{option}
										</MenuItem>
									))}
								</Select>
							</Box>
						</Box>
						<Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
							<Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>Status</Typography>
							<Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
								<Select
									size='small'
									required
									onChange={(event) => setSelectedStatus(event.target.value)}
									style={{ width: '100%' }}
									value={selectedStatus}
								>
									{statusOptions.map((option) => (
										<MenuItem
											value={option}
											key={option}
										>
											{option}
										</MenuItem>
									))}
								</Select>
							</Box>
						</Box>
						<Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
							<div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
								<Typography variant="body2" sx={{ fontWeight: 'bold', ml: 1, whiteSpace: 'nowrap' }}>Adjustment Code</Typography>
							</div>
							<Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
								<TextField
									required
									id='adjustment-code'
									fullWidth
									size="small"
								/>
							</Box>
						</Box>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>Process Month:<span style={{ color: 'red' }}>*</span></Typography>
						</Box>
						<Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
							<Box sx={{ ml: 1 }}>
								<LocalizationProvider dateAdapter={AdapterDayjs} >
									<DatePicker
										id='process-month-calendar'
										sx={{
											"& .MuiInputBase-input": {
												height: '35px',
												width: '70px',
												padding: '4px 8px',
											},
											"& .MuiInputBase-root": {
												fontSize: '16px',
											}
										}}
										slotProps={{
											textField: {
												size: "small",
												error: false
											},
										}}
										onChange={(date) => { setProcessMonthFrom(date); setProcessMonthTo(date); }}
										format="YYYY MM"
										openTo="year"
										views={['year', 'month']}
										selected={processMonthFrom}
										value={processMonthFrom}
									/>
								</LocalizationProvider>
							</Box>
							<Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap', textAlign: 'center' }}>to</Typography>
							<Box sx={{ ml: 1 }}>
								<LocalizationProvider dateAdapter={AdapterDayjs} >
									<DatePicker
										id='process-month-calendar'
										sx={{
											"& .MuiInputBase-input": {
												height: '35px',
												width: '70px',
												padding: '4px 8px',
											},
											"& .MuiInputBase-root": {
												fontSize: '16px',
											}
										}}
										slotProps={{
											textField: {
												size: "small",
												error: false
											},
										}}
										onChange={(date) => setProcessMonthTo(date)}
										format="YYYY MM"
										openTo="year"
										views={['year', 'month']}
										selected={processMonthTo}
										value={processMonthTo}
									/>
								</LocalizationProvider>
							</Box>
						</Box>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>Amount:</Typography>
						</Box>
						<Box sx={{ display: 'flex', mb: 1, ml: 1, alignItems: 'center' }}>
							<Box sx={{ maxWidth: 135 }}>
								<TextField
									required
									id='amount-from'
									fullWidth
									size="small"
								/>
							</Box>
							<Typography variant="body2" sx={{ fontWeight: 'bold', width: 35, whiteSpace: 'nowrap', textAlign: 'center' }}>to</Typography>
							<Box sx={{ maxWidth: 135 }}>
								<TextField
									required
									id='amount-to'
									fullWidth
									size="small"
								/>
							</Box>
						</Box>
						<Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
							<Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>Contract#</Typography>
							<Box sx={{ flex: 2, minWidth: 80, maxWidth: 136 }}>
								<TextField
									required
									id='contract-id'
									fullWidth
									size="small"
								/>
							</Box>
							<div style={{ height: '40px', width: '40px', border: '#d3d3d3 1px solid', borderRadius: '3px', textAlign: 'center', alignContent: 'center', marginLeft: '0.2rem' }}>
								<img
									style={{ height: '80%', width: '80%' }}
									src="Binoculars-icon.png"
									title="Filtering Data segment"
								></img>
							</div>
						</Box>
						<Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
							<div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
								<Typography variant="body2" sx={{ fontWeight: 'bold', ml: 1, whiteSpace: 'nowrap' }}>Contract Line#</Typography>
							</div>
							<Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
								<TextField
									required
									id='contract-line'
									fullWidth
									size="small"
								/>
							</Box>
						</Box>
						<Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
							<div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
								<Typography variant="body2" sx={{ fontWeight: 'bold', ml: 1, whiteSpace: 'nowrap' }}>SSMK</Typography>
							</div>
							<Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
								<TextField
									required
									id='ssmk'
									fullWidth
									size="small"
									multiline
								/>
							</Box>
						</Box>
						<Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
							<div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
								<Typography variant="body2" sx={{ fontWeight: 'bold', ml: 1, whiteSpace: 'nowrap' }}>Prepared By</Typography>
							</div>
							<Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
								<TextField
									required
									id='prepared'
									fullWidth
									size="small"
								/>
							</Box>
						</Box>
						<Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
							<div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
								<Typography variant="body2" sx={{ fontWeight: 'bold', ml: 1, whiteSpace: 'nowrap' }}>Reviewed By</Typography>
							</div>
							<Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
								<TextField
									required
									id='reviewed'
									fullWidth
									size="small"
								/>
							</Box>
						</Box>
						<Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
							<div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
								<Typography variant="body2" sx={{ fontWeight: 'bold', ml: 1, whiteSpace: 'nowrap' }}>Approved By</Typography>
							</div>
							<Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
								<TextField
									required
									id='approved'
									fullWidth
									size="small"
								/>
							</Box>
						</Box>

					</Box>
					<Button
						size="medium"
						variant="contained"
						endIcon={<SendIcon />}
						onClick={handleSubmit}
					>
						Submit
					</Button>
				</Box>
			}
			{!showSearch &&
				<Box>
					<Tooltip title="Maximize Search">
						<IconButton onClick={() => toggleSearch()}>
							<KeyboardArrowRight />
						</IconButton>
					</Tooltip>
				</Box>
			}
			{loading &&
				<Box sx={{ ml: '1rem', width: '100%', overflowX: 'scroll', mt: 7 }}>
					<LinearProgress />
				</Box>
			}
			
			{!loading && tableData.length > 0 &&
				<Box sx={{ ml: '1rem', width: '100%', overflowX: 'scroll', mt: 7, borderRadius: 1 }}>
					<SearchTable
						inputTableData={tableData}
						handleInvoiceClick={handleInvoiceClick}
					/>
				</Box>
			}
			{noRecordsFound &&
                <div style={{ display: 'flex', flexDirection: 'column', color: 'red', fontWeight: 'bold', paddingLeft: '1rem', paddingTop: '1rem', width: '100%', textAlign: 'center' }}>
                    No Records Found
                </div>
            }
		</Box>
	)
}
