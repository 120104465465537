import axios from "axios";
import { getToken } from "../../TokenAPI.js";
const SEARCH_URL = `${process.env.REACT_APP_BACKEND_MAINTAINANCE_URL}/maintenance/payment_adjustment/search`;
const ADD_OPEN = `${process.env.REACT_APP_BACKEND_MAINTAINANCE_URL}/maintenance/payment_adjustment/add/open`;
const ADD_DATASEG = `${process.env.REACT_APP_BACKEND_MAINTAINANCE_URL}/maintenance/payment_adjustment/add/dataseg`;
const ADD_COVSRC = `${process.env.REACT_APP_BACKEND_MAINTAINANCE_URL}/maintenance/payment_adjustment/add/covsrcsys`;
const ADD_NETWORK_ID = `${process.env.REACT_APP_BACKEND_MAINTAINANCE_URL}/maintenance/payment_adjustment/add/get_network_id`;
const UPDATE = `${process.env.REACT_APP_BACKEND_MAINTAINANCE_URL}/maintenance/payment_adjustment/update`;
const SAVE = `${process.env.REACT_APP_BACKEND_MAINTAINANCE_URL}/maintenance/payment_adjustment/save`;
const ADD_CONTR_ARR = `${process.env.REACT_APP_BACKEND_MAINTAINANCE_URL}/maintenance/payment_adjustment/add/contract_arrangement`;
const ADD_POL_ID = `${process.env.REACT_APP_BACKEND_MAINTAINANCE_URL}/maintenance/payment_adjustment/add/pol_id`;
const SEARCH_DELETE = `${process.env.REACT_APP_BACKEND_MAINTAINANCE_URL}/maintenance/payment_adjustment/delete`;



export const AdjustmentsSearchApi = async (requestBody) => {
  const bearerToken = await getToken();
  return axios.post(SEARCH_URL, requestBody, {
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Authorization: `Bearer ${bearerToken}`,
    },
  });
};

export const AdjustmentsAddOpenApi = async (requestBody) => {
  const bearerToken = await getToken();
  return axios.post(ADD_OPEN, requestBody, {
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Authorization: `Bearer ${bearerToken}`,
    },
  });
};

export const AdjustmentsAddDatasegApi = async (requestBody) => {
  const bearerToken = await getToken();
  return axios.post(ADD_DATASEG, requestBody, {
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Authorization: `Bearer ${bearerToken}`,
    },
  });
};

export const AdjustmentsAddCovSrcApi = async (requestBody) => {
  const bearerToken = await getToken();
  return axios.post(ADD_COVSRC, requestBody, {
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Authorization: `Bearer ${bearerToken}`,
    },
  });
};

export const AdjustmentsAddPolIdApi = async (requestBody) => {
	const bearerToken = await getToken();
	return axios.post(
		ADD_POL_ID,
		requestBody,
		{
			headers: {
				'Cache-Control': 'no-cache',
				'Pragma': 'no-cache',
				'Authorization': `Bearer ${bearerToken}`
			}
		});
}

export const AdjustmentsNetworkIdApi = async (requestBody) => {
	const bearerToken = await getToken();
	return axios.post(
		ADD_NETWORK_ID,
		requestBody,
		{
			headers: {
				'Cache-Control': 'no-cache',
				'Pragma': 'no-cache',
				'Authorization': `Bearer ${bearerToken}`
			}
		});
}

export const AdjustmentsUpdateApi = async (requestBody) => {
  const bearerToken = await getToken();
  return axios.post(UPDATE, requestBody, {
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Authorization: `Bearer ${bearerToken}`,
    },
  });
};

export const AdjustmentsSaveApi = async (requestBody) => {
  const bearerToken = await getToken();
  return axios.post(SAVE, requestBody, {
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Authorization: `Bearer ${bearerToken}`,
    },
  });
};

export const AdjustmentsAddContrArrApi = async (requestBody) => {
  const bearerToken = await getToken();
  return axios.post(ADD_CONTR_ARR, requestBody, {
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Authorization: `Bearer ${bearerToken}`,
    },
  });
};

export const AdjustmentsSearchDeleteApi = async (requestBody) => {
	const bearerToken = await getToken();
	return axios.post(
		SEARCH_DELETE,
		requestBody,
		{
			headers: {
				'Cache-Control': 'no-cache',
				'Pragma': 'no-cache',
				'Authorization': `Bearer ${bearerToken}`	
			}
		});
}
