import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useEffect, useState } from "react";
import { MemberDetails } from "./MemberDetails/MemberDetails";
import MemberAdjustments from "./MemberAdjustments/MemberAdjustments";
import MemberMemberInfo from "./MemberMemberInfo/MemberMemberInfo";
import MemberProviderHistory from "./MemberProviderHistory/MemberProviderHistory";
import MemberCoverageHistory from "./MemberCoverageHistory/MemberCoverageHistory";
import MemberTransactionHistory from "./MemberTransactionHistory/MemberTranscationHistory"
import MemberHistorySearch from "./MemberHistorySearch/MemberHistorySearch";
import MemberTotalCap from "./MemberTotalCap/MemberTotalCap";
import { DataSegmentApi } from './MemberHistorySearch/DataSegmentApi';
import { HelpButtons } from '../../../components/helpPrintButtons.js';
import { Box, Grid } from "@mui/material";

export const MemberHistoryTab = () => {
	const [value, setValue] = useState(0);
	const [showMemberSearchTable, setShowMemberSearchTable] = useState(false);
	const [selectedMemberData, setSelectedMemberData] = useState(null); // State to hold member data
	const [dataSegments, setDataSegments] = useState([]);
	const [disabledTabs, setDisabledTabs] = useState([
		false,
		true,
		true,
		true,
		true,
		true,
		true,
		true,
	]);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const switchTabs = (newValue) => {
		setValue(newValue);
	};
	const handleTabChange = (memberData) => {
		const index = value;
		const updatedDisabledTabs = disabledTabs.map(() => false);
		setValue(index + 1);
		setDisabledTabs(updatedDisabledTabs);
		setShowMemberSearchTable(false);
		setSelectedMemberData(memberData);
	};
	const sections = [
		"Search",
		"Member Detail",
		"Total Cap",
		"Adjustments",
		"Member info",
		"Provider History",
		"Coverage History",
		"Transaction History",
	];
	useEffect(() => {
		// Fetch the data when the component mounts  
		DataSegmentApi()
			.then(response => {
				// Set the fetched data to the dataSegments state  
				setDataSegments(response.data);
			})
			.catch(error => {
				console.error('There was an error fetching the data segments:', error);
			});
	}, []);
	const makeTabComponent = () => {
		if (value === 0)
			return <MemberHistorySearch handleTabChange={handleTabChange} inputDataSegments={dataSegments}/>;
		if (value === 1) {
			return <MemberDetails memberData={selectedMemberData} switchTabs={switchTabs} />;
		}
		if (value === 2) {
			return <MemberTotalCap memberData={selectedMemberData} />;
		}
		if (value === 3) {
			return <MemberAdjustments memberData={selectedMemberData} />;
		}
		if (value === 4) {
			return <MemberMemberInfo memberData={selectedMemberData} />;
		}
		if (value === 5) {
			return <MemberProviderHistory memberData={selectedMemberData} />;
		}
		if (value === 6) {
			return <MemberCoverageHistory memberData={selectedMemberData} />;
		}
		if (value === 7) {
			return <MemberTransactionHistory memberData={selectedMemberData} />;
		}
	};
	return (
		<Grid id='reportsMainTable' sx={{ width: "100%", maxHeight: "87vh" }}>
			<div style={{ position: 'absolute', top: '0', right: '20px', zIndex: 1 }}>
				<HelpButtons index={2} />
			</div>
			<Tabs
				value={value}
				onChange={handleChange}
				sx={{ "& .MuiTabs-indicator": { backgroundColor: "#002153" }, pt: 1.5 }}
			>
				{sections.map((section, index) => (
				<Tab
					label={section}
					disabled={disabledTabs[index]}
					key={section}
				/>
				))}
			</Tabs>
			{makeTabComponent()}
		</Grid>
	);
};
