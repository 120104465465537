import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { getToken } from "../../TokenAPI.js";
import { Button, Box, Input, Paper, Grid, Container } from "@mui/material";
import {
  adminCodesAddAxiosClient,
  admincheckupdateAxiosClient,
} from "./CodesSearchApi";
import { adminCodesUpdateAxiosClient } from "./CodesUpdateApi";
import Papa from "papaparse";
import { useSelector } from "react-redux";

export const CodesUpload = () => {
  const bearerToken = getToken();
  const headers = {
      'Authorization': `Bearer ${bearerToken}`
    }
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const permissions = useSelector((store) => store.user.permissions);
  // const username = useSelector((store) => store.user.user.username);
  const updatePermission =
    permissions.admin_codes.update === "Y" ? true : false;
  const allowedExtensions = [".csv"];
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (
      file &&
      allowedExtensions.some((extension) =>
        file.name.toLowerCase().endsWith(extension)
      )
    ) {
      Papa.parse(file, {
        header: true,
        complete: function (results) {
          const headers = results.meta.fields;
          const requiredHeaders = [
            "LNG_DESC",
            "ACTV_IND",
            "ECAP_TYP_CD",
            "ECAP_CD",
            "SHRT_DESC",
          ];
          const missingHeaders = requiredHeaders.filter(
            (header) => !headers.includes(header)
          );

          if (missingHeaders.length === 0) {
            setSelectedFile(file);
            setErrorMessage("");
          } else {
            setSelectedFile(null);
            setErrorMessage(
              `Invalid file. Missing headers: ${missingHeaders.join(", ")}`
            );
          }
        },
      });
    } else {
      setSelectedFile(null);
      setErrorMessage("Invalid file type. Please select a CSV file.");
    }
  };
  const upload = () => {
    if (!selectedFile) {
      setErrorMessage("Please select a file to upload");
      return;
    }

    Papa.parse(selectedFile, {
      header: true,
      complete: function (results) {
        const data = results.data;
        const promises = data.map((row) => {
          const requestBody = {
            ecap_type_code: row.ECAP_TYP_CD,
            ecap_code: row.ECAP_CD,
          };

          return admincheckupdateAxiosClient
            .post("", requestBody, headers)
            .then((response) => {
              const data = response.data;
              if (data) {
                const requestBodyForUpdate = {
                  ecap_type_code: row.ECAP_TYP_CD,
                  ecap_code: row.ECAP_CD,
                  short_desc: row.SHRT_DESC,
                  long_desc: row.LNG_DESC,
                  active_ind: row.ACTV_IND,
                  user:localStorage.getItem('sub')
                };
                setErrorMessage(
                  "code already exists, updating for ecapcode:" +
                    row.ECAP_CD +
                    " and ecaptypecode:" +
                    row.ECAP_TYP_CD
                );
                return adminCodesUpdateAxiosClient.post(
                  "",
                  requestBodyForUpdate,
                  headers
                );
              } else {
                const requestBodyForAdd = {
                  ecap_type_code: row.ECAP_TYP_CD,
                  ecap_code: row.ECAP_CD,
                  short_description: row.SHRT_DESC,
                  long_description: row.LNG_DESC,
                  active_ind: row.ACTV_IND,
                  user: localStorage.getItem('sub')
                };
                setErrorMessage(
                  "code does not exist, adding for ecapcode:" +
                    row.ECAP_CD +
                    " and ecaptypecode:" +
                    row.ECAP_TYP_CD
                );
                return adminCodesAddAxiosClient.post("", requestBodyForAdd, headers);
              }
            });
        });

        Promise.all(promises)
          .then((responses) => {
            console.log(responses);
            // Run your code after all Axios calls are complete
            setSuccessMessage("File uploaded successfully");
            setSelectedFile(selectedFile);
            setErrorMessage("");
          })
          .catch((error) => {
            console.log(error);
            setSelectedFile(null);
            setErrorMessage("Error occurred during APIcalls");
          });
      },
      error: function (error) {
        setSelectedFile(null);
        setErrorMessage("Error parsing the CSV file.");
      },
    });
  };
  const cleanSubmit = () => {
    setErrorMessage("");
    setSuccessMessage("");
    setSelectedFile(null);
  };
  const createAndDownload = () => {
    const headers = [
      "LNG_DESC",
      "ACTV_IND",
      "ECAP_TYP_CD",
      "ECAP_CD",
      "SHRT_DESC",
    ];
    const csvData = Papa.unparse([headers]);

    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "sample.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <Grid
      id='reportsMainTable'
      container
      spacing={2}
      style={{ margin: "70px 0px 0px 0px" }}
      sx={{ my: 1, maxHeight: "800px", overflowY: "auto", padding: 2 }}
    >
      <Grid
        xs={12}
        sm={12}
        md={12}
        lg={12}
      >
        <Container
          component='main'
          maxWidth='md'
          sx={{ mb: 4 }}
        >
          <Paper
            variant='outlined'
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
          >
            <Box sx={{ my: 1 }}>
              <Grid
                container
                spacing={4}
                item
                justifyContent='center'
              >
                <Grid item>
                  <Typography>
                    Please select a CSV (Comma Separated Values) file
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                justifyContent='center'
              >
                <Grid item>
                  <Typography variant='h6'>Select File to Upload </Typography>
                </Grid>
                <Grid
                  item
                  alignItems='stretch'
                  style={{ display: "flex" }}
                >
                  <label htmlFor='file-upload'>
                    <input
                      id='file-upload'
                      type={"file"}
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                      inputProps={{ directory: "", webkitdirectory: "" }}
                    />
                    <Button
                      component='span'
                      variant='contained'
                      color='primary'
                    >
                      Choose File
                    </Button>
                  </label>
                  <span style={{ marginLeft: 15 }}>
                    {selectedFile ? selectedFile.name : "No file chosen"}
                  </span>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={4}
                item
                justifyContent='center'
              >
                <Grid item>
                  <Button
                    type='next'
                    variant='contained'
                    sx={{ mt: 3, ml: 1 }}
                    size='large'
                    onClick={upload}
                    disabled={!updatePermission}
                  >
                    Upload
                  </Button>
                  <Button
                    type='next'
                    variant='contained'
                    sx={{ mt: 3, ml: 1 }}
                    size='large'
                    color='error'
                    onClick={cleanSubmit}
                  >
                    Cancel
                  </Button>
                  <Button
                    type='next'
                    variant='contained'
                    sx={{ mt: 3, ml: 1 }}
                    size='large'
                    color='primary'
                    onClick={createAndDownload}
                    disabled={!updatePermission}
                  >
                    Download Sample File
                  </Button>
                </Grid>
              </Grid>
              {errorMessage && (
                <Grid
                  container
                  spacing={4}
                  item
                  justifyContent='center'
                >
                  <Grid item>
                    <Typography
                      variant='body1'
                      style={{ color: "red" }}
                    >
                      {errorMessage}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {successMessage && (
                <Grid
                  container
                  spacing={4}
                  item
                  justifyContent='center'
                >
                  <Grid item>
                    <Typography
                      variant='body1'
                      style={{ color: "green" }}
                    >
                      {successMessage}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Paper>
        </Container>
      </Grid>
    </Grid>
  );
};
