import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useState, useEffect } from 'react';
import { DemographicsSearch } from './DemographicsSearch';
import { DemographicsProviderDetail } from './DemographicsProviderDetail';
import { HelpButtons } from '../../../components/helpPrintButtons.js';


const sections = [
    "Search",
    " Provider Detail ",
];

export const ProviderDemographics = ({ setTab }) => {
    const [value, setValue] = useState(0);
    const [disabledTabs, setDisabledTabs] = useState([
        false,
        true,
    ]);
    const [provId, setProvId] = useState();
    const [provTaxIdNbr, setProvTaxIdNbr] = useState();
    const [provTaxIdTypCd, setProvTaxIdTypCd] = useState();
    const [effectivedate, seteffectivedate] = useState();
    const [corpmpin, setcorpmpin] = useState();
    const [enddate, setenddate] = useState();
    const [lastname, setlastname] = useState();
    // set tab selection value (0, 1, 2, 3, 4)
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const switchTabs = (newValue) => {
        setValue(newValue);
    };
    // only adjust component if value changes
    const MakeTabComponent = () => {
        const [currentTab, setCurrentTab] = useState(null);
        useEffect(() => {
            setCurrentTab(makeTabComponent(value));
        }, [value]);
        return currentTab;
    };
    // actual components to show depending on tab value
    const makeTabComponent = (value) => {
        if (value === 0) {
            return (
                <DemographicsSearch
                    switchTabs={switchTabs}
                    setTab={setTab}
                    setProvId={setProvId}
                    setProvTaxIdNbr={setProvTaxIdNbr}
                    setProvTaxIdTypCd={setProvTaxIdTypCd}
                    seteffectivedate={seteffectivedate}
                    setcorpmpin={setcorpmpin}
                    setenddate={setenddate}
                    setlastname={setlastname}

                />
            );
        }
        if (value === 1) {
            return (
                <DemographicsProviderDetail
                    inputProvId={provId}
                    inputProvTaxIdNbr={provTaxIdNbr}
                    inputProvTaxIdTypCd={provTaxIdTypCd}
                    inputeffectivedate={effectivedate}
                    inputcorpmpin={corpmpin}
                    inputenddate={enddate}
                    inputlastname={lastname}

                />
            );
        }

    };
    return (
        <div 
        id='reportsMainTable'
        sx={{ width: "100%" }}
        >
            <div style={{ position: 'absolute', top: '0', right: '20px', zIndex: 1 }}>
				<HelpButtons index={3} />
			</div>
            <Tabs
                value={value}
                onChange={handleChange}
                sx={{ "& .MuiTabs-indicator": { backgroundColor: "#002153" }, pt: 1.5 }}
            >
                {sections.map((section, index) => (
                    <Tab
                        label={section}
                        disabled={disabledTabs[index]}
                    />
                ))}
            </Tabs>
            <MakeTabComponent />
        </div>
    );
};
