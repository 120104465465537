import { useState, useEffect } from 'react';
import { Box } from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { getDataSegments } from '../Home/dataSegmentApi';
import { ErrorsMonthly } from './MonthlyProcessed/Monthly';
import { ErrorsAging } from './Aging/Aging';
import { ErrorsDaily } from './DailyInterface/Daily'
import { clearAllErrorsAging, clearAllErrorsDaily, clearAllErrorsMonthly } from './errorPersistence';
import { HelpButtons } from '../../components/helpPrintButtons.js';

const sections = [
    "Monthly Processed",
    "Aging",
    "Daily Interface",
];

export const Errors = () => {

    const [value, setValue] = useState(0);
    // set tab selection value (0, 1, 2, 3, 4)
    const [dataSegments, setDataSegments] = useState([]);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // only adjust component if value changes
    const MakeTabComponent = () => {
        const [currentTab, setCurrentTab] = useState(null);
        useEffect(() => {
            setCurrentTab(makeTabComponent(value));
        }, []);
        return currentTab;
    };

    useEffect(() => {
        setDataSegments(getDataSegments());
    }, []);

    // actual components to show depending on tab value
    const makeTabComponent = (value) => {
        if (value === 0) {
            clearAllErrorsAging();
            clearAllErrorsDaily();
            return <ErrorsMonthly />
        }
        if (value === 1) {
            clearAllErrorsMonthly();
            clearAllErrorsDaily();
            return <ErrorsAging />;
        }
        if (value === 2) {
            clearAllErrorsMonthly();
            clearAllErrorsAging();
            return <ErrorsDaily />
        }
    };

    return (
        <Box id='reportsMainTable' sx={{ width:'100%' }}>
            <div style={{ position: 'absolute', top: '0', right: '20px', zIndex: 1 }}>
				<HelpButtons index={5} />
			</div>
            <Tabs
                value={value}
                onChange={handleChange}
                sx={{ "& .MuiTabs-indicator": { backgroundColor: "#002153" }, pt: 1.5 }}
            >
                {sections.map((section) => (
                    <Tab key={section} label={section} />
                ))}
            </Tabs>
            <MakeTabComponent />
        </Box>
    );
};
export default Errors;